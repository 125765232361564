<template>
  <v-card>
    <v-container>
      <v-card-title class="mt-n3">
        <span class="primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form v-model="isFormValid" @submit.prevent="addTipoDeValor">
          <v-row class="mt-n5">
            <!-- tipo de valor -->
            <v-col cols="6" md="6">
              <v-autocomplete
                v-model="tipoValorSelected"
                :items="tipoValorItems"
                :rules="rules.required"
                label="Tipo de valor"
                return-object
                @change="changeTipoValor()"
                item-text="tvNombre"
                item-value="tvId"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col
              v-if="
                esPVIV &&
                agregarCartera &&
                tipoValorSelected &&
                tipoValorSelected.esCartera
              "
              cols="3"
              md="3"
            >
              <v-autocomplete
                ref="bancos-autocomplete"
                v-model="bancoSelected"
                :items="bancosItems"
                :search-input.sync="bancosAutocompleteInput"
                item-text="value1"
                item-value="id"
                :disabled="!tipoValorSelected"
                :filled="!tipoValorSelected"
                outlined
                persistent-hint
                :hint="tipoValorSelected ? 'Ingrese código o nombre' : ''"
                clearable
                return-object
                dense
                autocomplete="off"
                label="Bancos"
              >
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.value1 }}</v-list-item-title>
                    <v-list-item-subtitle
                      >Código: {{ item.value2 }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-slot:append>
                  <v-progress-circular
                    indeterminate
                    size="28"
                    v-if="bancosLoading"
                    color="primary"
                  ></v-progress-circular>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- nombre del banco -->
            <v-col v-else cols="3" md="3">
              <v-text-field
                type="text"
                outlined
                dense
                filled
                readonly
                label="Banco"
                v-model="bancoNombre"
                autocomplete="off"
                disabled
              ></v-text-field>
            </v-col>
            <!-- nombre de la cuenta bancaria -->
            <v-col cols="3" md="3">
              <v-text-field
                type="text"
                outlined
                dense
                filled
                readonly
                label="Cuenta"
                v-model="cuentaNombre"
                autocomplete="off"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n8 mb-n7">
            <!-- numero -->
            <v-col cols="4">
              <v-text-field
                v-model.trim="numero"
                type="text"
                label="Número"
                dense
                :disabled="!this.disableNumero"
                :filled="!this.disableNumero"
                outlined
                :rules="
                  this.disableNumero
                    ? rules.required.concat(rules.positiveNumberHigher0, [
                        rules.maxLength(numero, 10),
                      ])
                    : []
                "
              ></v-text-field>
            </v-col>
            <!-- fecha -->
            <v-col cols="4" md="4">
              <v-menu
                ref="fecha-desde"
                v-model="menuFechaValor"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaValorSelected"
                    label="Fecha"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaValor = parseDateToIso(fechaValorSelected)"
                    outlined
                    dense
                    clearable
                    v-mask="'##/##/####'"
                    v-on="on"
                    :rules="disableFecha ? rules.required : []"
                    :disabled="!disableFecha"
                    :filled="!disableFecha"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaValor"
                  no-title
                  @change="fechaValorSelected = formatDate(fechaValor)"
                  @input="menuFechaValor = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- importe -->
            <v-col cols="4" md="4">
              <currency-input
                v-model="importe"
                label="Importe"
                :options="currencyOptions"
                :rules="rules.required"
                :hideDetails="'auto'"
              ></currency-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="ml-n4 pl-7 pr-0">
              <v-text-field
                v-if="
                  (esPVIV || esMovBanc) &&
                  tipoValorSelected &&
                  tipoValorSelected.esPropio
                "
                type="text"
                outlined
                dense
                label="A orden de"
                v-model="aOrdenDe"
              ></v-text-field>
            </v-col>
            <v-col cols="8" align="end" class="text-right to-right pt-4 pr-0">
              <v-btn outlined @click="toggleNuevoTipoValor"> Cerrar </v-btn>
              <v-btn
                type="submit"
                class="ml-4"
                :loading="loadingSave"
                :disabled="!isFormValid"
                color="primary"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <ConfirmDialog
        :text="textConfirm"
        :openConfirm="openConfirmDialog"
        :submitBtn="'SÍ'"
        :cancelBtn="'NO'"
        @update:openConfirm="openConfirmDialog = false"
        @onConfirm="confirmSaveValor"
      />
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import { mapActions } from "vuex";

export default {
  name: "NuevoTipoValorOP",
  directives: { mask },
  components: {
    CurrencyInput,
    ConfirmDialog,
  },
  props: {
    empresaSelected: {
      type: Object,
    },
    fechaSelected: {
      type: String,
    },
    importeSugerido: {
      type: Number,
    },
    movProvId: {
      type: Number,
    },
    cotizacion: {
      type: Number,
    },
    monedaOPSelected: {
      type: Object,
    },
    retencionesItems: {
      type: Array,
    },
    tipoValor: {
      type: String,
    },
    esPVIV: {
      type: Boolean,
      default: false,
    },
    agregarCartera: {
      type: Boolean,
      default: false,
    },
    esMovBanc: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    rules: rules,
    calendarIcon: enums.icons.CALENDAR,
    formEditTitle: "Tipo de valor",
    textConfirm:
      "El número de cheque anterior no está utilizado. ¿Desea continuar?",
    tipoValorSelected: null,
    loadingSave: false,
    cuentaNombre: null,
    aOrdenDe: "",
    bancoNombre: null,
    importe: null,
    importeDistintMoneda: null,
    numero: null,
    menuFechaValor: false,
    fechaSiguiente: null,
    fechaValorSelected: null,
    fechaValor: null,
    monedaDefecto: null,
    tipoValorItems: [],
    openConfirmDialog: false,
    isFormValid: false,
    disableFecha: false,
    disableNumero: false,
    haveBanco: false,
    haveCuenta: false,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999,
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true,
    },
    bancosItems: [],
    bancoSelected: null,
    bancosAutocompleteInput: null,
    bancosLoading: false,
  }),
  watch: {
    bancosAutocompleteInput(val) {
      if (val && val.trim().length >= 2) this.getBancosFilter();
    },
  },
  created() {
    this.setTiposDeValor();
  },
  methods: {
    ...mapActions({
      getTiposValoresOp: "proveedores/getTiposValoresOp",
      getTiposValoresMovBanc: "fondos/getTiposValoresMovBanc",
      validarChequeNoUsado: "proveedores/validarChequeNoUsado",
      validarChequeDentroPeriodo: "proveedores/validarChequeDentroPeriodo",
      validarNumeroAnteriorUsado: "proveedores/validarNumeroAnteriorUsado",
      getParametroGralValidarChequera:
        "proveedores/getParametroGralValidarChequera",
      saveNumeroTipoValor: "proveedores/saveNumeroTipoValor",
      getUltimoNumTipoValor: "proveedores/getUltimoNumTipoValor",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      listarBancosByQuery: "fondos/listarBancosByQuery",
      setAlert: "user/setAlert",
    }),
    async setTiposDeValor() {
      const monedasResponse = await this.getMonedasTaxonomy("habListado");
      const monedaVig = monedasResponse.find((x) => x.bool == true);
      if (monedaVig) this.monedaDefecto = monedaVig;
      const data = {
        entFacId: this.empresaSelected.id,
        monedaId: this.monedaOPSelected.id,
        agregarCartera: this.agregarCartera,
      };
      if (this.esMovBanc) {
        const response = await this.getTiposValoresMovBanc(data);
        this.tipoValorItems = response;
      } else {
        const response = await this.getTiposValoresOp(data);
        this.tipoValorItems = response;
      }
      this.importe = this.importeSugerido;
      if (this.tipoValor != "") {
        this.tipoValorSelected = this.tipoValorItems.find(
          (x) => x.tvNombre == this.tipoValor
        );
        this.changeTipoValor()
      }
    },
    async addTipoDeValor() {
      if (this.importe == 0) {
        this.setAlert({
          type: "error",
          message: "Debe ingresar un importe mayor a 0.",
        });
      } else {
        if (this.monedaDefecto.id != this.monedaOPSelected.id) {
          this.importeDistintMoneda = this.importe;
          this.importe = this.importe * this.cotizacion;
        } else {
          this.importeDistintMoneda = null;
        }
        //se chequea si es propio o se valida numero de cheque
        if (
          (this.tipoValorSelected.validarNumeracionMovimientoBancario ||
            this.tipoValorSelected.esPropio) &&
          this.tipoValorSelected.numeroBool
        ) {
          if (this.retencionesItems.length > 0) {
            const tipoValorIgual = this.retencionesItems.find(
              (x) =>
                x.tvId == this.tipoValorSelected.tvId &&
                x.bctaId == this.tipoValorSelected.bctaId &&
                x.bancoId == this.tipoValorSelected.bancoId &&
                x.numero == this.numero
            );
            if (tipoValorIgual) {
              this.setAlert({
                type: "error",
                message:
                  "Ya se ingreso un tipo de valor con el mismo número de cheque.",
              });
            } else {
              const data = {
                BCtaId: this.tipoValorSelected.bctaId,
                TvId: this.tipoValorSelected.tvId,
                Numero: this.numero,
                MProvId: this.movProvId > 0 ? this.movProvId : 0,
              };
              const chequeUsado = await this.validarChequeNoUsado(data);
              // si se uso el cheque, salta error
              if (chequeUsado) {
                this.setAlert({
                  type: "error",
                  message: "El número de cheque ya fue utilizado.",
                });
              } else {
                //si no se uso el cheque, se chequea si esta dentro del periodo, segun parametro gral
                const paramValidarcheq =
                  await this.getParametroGralValidarChequera();
                if (paramValidarcheq) {
                  const chequeCorrecto = await this.validarChequeDentroPeriodo(
                    data
                  );
                  if (!chequeCorrecto) {
                    this.setAlert({
                      type: "error",
                      message: "El número de cheque no entra dentro del rango.",
                    });
                  } else {
                    const numeroAnteriorUsado =
                      await this.validarNumeroAnteriorUsado(data);
                    if (!numeroAnteriorUsado) this.openConfirmDialog = true;
                    else this.saveTipoValor();
                  }
                } else {
                  const numeroAnteriorUsado =
                    await this.validarNumeroAnteriorUsado(data);
                  if (!numeroAnteriorUsado) this.openConfirmDialog = true;
                  else this.saveTipoValor();
                }
              }
            }
          } else {
            const data = {
              BCtaId: this.tipoValorSelected.bctaId,
              TvId: this.tipoValorSelected.tvId,
              Numero: this.numero,
              MProvId: this.movProvId > 0 ? this.movProvId : 0,
            };
            const chequeUsado = await this.validarChequeNoUsado(data);
            // si se uso el cheque, salta error
            if (chequeUsado) {
              this.setAlert({
                type: "error",
                message: "El número de cheque ya fue utilizado.",
              });
            } else {
              //si no se uso el cheque, se chequea si esta dentro del periodo, segun parametro gral
              const paramValidarcheq =
                await this.getParametroGralValidarChequera();
              if (paramValidarcheq) {
                const chequeCorrecto = await this.validarChequeDentroPeriodo(
                  data
                );
                if (!chequeCorrecto) {
                  this.setAlert({
                    type: "error",
                    message: "El número de cheque no entra dentro del rango.",
                  });
                } else {
                  const numeroAnteriorUsado =
                    await this.validarNumeroAnteriorUsado(data);
                  if (!numeroAnteriorUsado) this.openConfirmDialog = true;
                  else this.saveTipoValor();
                }
              } else {
                const numeroAnteriorUsado =
                  await this.validarNumeroAnteriorUsado(data);
                if (!numeroAnteriorUsado) this.openConfirmDialog = true;
                else this.saveTipoValor();
              }
            }
          }
        } else {
          this.saveTipoValor();
        }
      }
    },
    async saveTipoValor() {
      this.loadingSave = true;
      if (
        (this.tipoValorSelected.validarNumeracionMovimientoBancario ||
          this.tipoValorSelected.esPropio) &&
        this.tipoValorSelected.numeroBool
      ) {
        const dataToSaveNum = {
          tvId: this.tipoValorSelected.tvId,
          numero: this.numero,
        };
        const response = await this.saveNumeroTipoValor(dataToSaveNum);
        if (response) {
          const item = {
            ...this.tipoValorSelected,
            imp: this.importe,
            importeComp: this.importeDistintMoneda,
            fecha: this.fechaValorSelected,
            numero: this.numero,
            aOrdenDe: this.esPVIV || this.esMovBanc ? this.aOrdenDe : null,
          };
          this.$emit("addTipoDeValor", item);
          this.$emit("toggleNuevoTipoValor");
          this.loadingSave = false;
        }
      } else {
        const item = {
          ...this.tipoValorSelected,
          imp: this.importe,
          importeComp: this.importeDistintMoneda,
          fecha: this.fechaValorSelected,
          numero: this.numero,
          aOrdenDe: this.esPVIV || this.esMovBanc ? this.aOrdenDe : null,
        };
        this.$emit("addTipoDeValor", item);
        this.$emit("toggleNuevoTipoValor");
      }
    },
    confirmSaveValor() {
      this.saveTipoValor();
    },
    async changeTipoValor() {
      this.numero = null;
      this.fechaValorSelected = null;
      if (this.tipoValorSelected != undefined) {
        // se chequea si pide fecha
        if (this.tipoValorSelected.fechaBool) {
          this.disableFecha = true;
          // se formatea la fecha con un dia mas y se asignan valores
          const [dia, mes, año] = this.fechaSelected.split("/").map(Number);
          let fecha = new Date(año, mes - 1, dia);
          fecha.setDate(fecha.getDate() + 1);
          const diaSiguiente = ("0" + fecha.getDate()).slice(-2);
          const mesSiguiente = ("0" + (fecha.getMonth() + 1)).slice(-2);
          const añoSiguiente = fecha.getFullYear();
          this.fechaSiguiente = `${diaSiguiente}/${mesSiguiente}/${añoSiguiente}`;
          this.fechaValorSelected = this.fechaSiguiente;
          this.fechaValor = this.parseDateToIso(this.fechaSiguiente);
        } else this.disableFecha = false;
        // se chequea si pide numero
        if (this.tipoValorSelected.numeroBool) this.disableNumero = true;
        else this.disableNumero = false;
        // se chequea si trae banco
        if (this.tipoValorSelected.bancoId > 0) {
          this.haveBanco = true;
          this.bancoNombre = this.tipoValorSelected.bancoNombre;
        } else {
          this.haveBanco = false;
          this.bancoNombre = null;
        }
        //se chequea si trae cuenta de banco
        if (this.tipoValorSelected.bctaId > 0) {
          this.haveCuenta = true;
          this.cuentaNombre = this.tipoValorSelected.bctaNombre;
        } else {
          this.haveCuenta = false;
          this.cuentaNombre = null;
        }
        if (this.tipoValorSelected.numeroBool) {
          const response = await this.getUltimoNumTipoValor(
            this.tipoValorSelected.tvId
          );
          if (response > 0) this.numero = response + 1;
        }
      } else {
        this.disableFecha = false;
        this.disableNumero = false;
        this.haveBanco = false;
        this.haveCuenta = false;
      }
    },
    getBancosFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.bancosItems?.some(
            (x) =>
              x.value2 == this.bancosAutocompleteInput ||
              x.value1 == this.bancosAutocompleteInput
          )
        ) {
          const bancoCoincidente = this.bancosItems.find(
            (x) =>
              x.value2 === this.bancosAutocompleteInput ||
              x.value1 === this.bancosAutocompleteInput
          );

          if (bancoCoincidente) {
            this.bancoNombre = bancoCoincidente.value1;
            this.tipoValorSelected.bancoId = bancoCoincidente.id;
            this.tipoValorSelected.bancoNombre = this.bancoNombre;
          }
          return;
        } else {
          this.bancosLoading = true;
          const response = await this.listarBancosByQuery({
            input: this.bancosAutocompleteInput,
          });
          if (response && response.length > 0) {
            this.bancosItems = response;
            this.bancoNombre = response.value1;
            this.tipoValorSelected.bancoId = response.id;
            this.tipoValorSelected.bancoNombre = this.bancoNombre;
          }
        }
        this.bancosLoading = false;
      }, 500);
    },
    toggleNuevoTipoValor() {
      this.$emit("toggleNuevoTipoValor");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
  },
};
</script>

<style scoped>
</style>