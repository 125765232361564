var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"mt-n3"},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.addTipoDeValor($event)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{staticClass:"mt-n5"},[_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.tipoValorItems,"rules":_vm.rules.required,"label":"Tipo de valor","return-object":"","item-text":"tvNombre","item-value":"tvId","outlined":"","clearable":"","dense":""},on:{"change":function($event){return _vm.changeTipoValor()}},model:{value:(_vm.tipoValorSelected),callback:function ($$v) {_vm.tipoValorSelected=$$v},expression:"tipoValorSelected"}})],1),(
              _vm.esPVIV &&
              _vm.agregarCartera &&
              _vm.tipoValorSelected &&
              _vm.tipoValorSelected.esCartera
            )?_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-autocomplete',{ref:"bancos-autocomplete",attrs:{"items":_vm.bancosItems,"search-input":_vm.bancosAutocompleteInput,"item-text":"value1","item-value":"id","disabled":!_vm.tipoValorSelected,"filled":!_vm.tipoValorSelected,"outlined":"","persistent-hint":"","hint":_vm.tipoValorSelected ? 'Ingrese código o nombre' : '',"clearable":"","return-object":"","dense":"","autocomplete":"off","label":"Bancos"},on:{"update:searchInput":function($event){_vm.bancosAutocompleteInput=$event},"update:search-input":function($event){_vm.bancosAutocompleteInput=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.value1))]),_c('v-list-item-subtitle',[_vm._v("Código: "+_vm._s(item.value2)+" ")])],1)]}},{key:"append",fn:function(){return [(_vm.bancosLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"28","color":"primary"}}):_vm._e()]},proxy:true}],null,false,2921961447),model:{value:(_vm.bancoSelected),callback:function ($$v) {_vm.bancoSelected=$$v},expression:"bancoSelected"}})],1):_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","filled":"","readonly":"","label":"Banco","autocomplete":"off","disabled":""},model:{value:(_vm.bancoNombre),callback:function ($$v) {_vm.bancoNombre=$$v},expression:"bancoNombre"}})],1),_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","filled":"","readonly":"","label":"Cuenta","autocomplete":"off","disabled":""},model:{value:(_vm.cuentaNombre),callback:function ($$v) {_vm.cuentaNombre=$$v},expression:"cuentaNombre"}})],1)],1),_c('v-row',{staticClass:"mt-n8 mb-n7"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"type":"text","label":"Número","dense":"","disabled":!this.disableNumero,"filled":!this.disableNumero,"outlined":"","rules":this.disableNumero
                  ? _vm.rules.required.concat(_vm.rules.positiveNumberHigher0, [
                      _vm.rules.maxLength(_vm.numero, 10) ])
                  : []},model:{value:(_vm.numero),callback:function ($$v) {_vm.numero=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"numero"}})],1),_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('v-menu',{ref:"fecha-desde",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha","append-icon":_vm.calendarIcon,"autocomplete":"not","hint":"Formato DD/MM/AAAA","outlined":"","dense":"","clearable":"","rules":_vm.disableFecha ? _vm.rules.required : [],"disabled":!_vm.disableFecha,"filled":!_vm.disableFecha},on:{"blur":function($event){_vm.fechaValor = _vm.parseDateToIso(_vm.fechaValorSelected)}},model:{value:(_vm.fechaValorSelected),callback:function ($$v) {_vm.fechaValorSelected=$$v},expression:"fechaValorSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaValor),callback:function ($$v) {_vm.menuFechaValor=$$v},expression:"menuFechaValor"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaValorSelected = _vm.formatDate(_vm.fechaValor)},"input":function($event){_vm.menuFechaValor = false}},model:{value:(_vm.fechaValor),callback:function ($$v) {_vm.fechaValor=$$v},expression:"fechaValor"}})],1)],1),_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('currency-input',{attrs:{"label":"Importe","options":_vm.currencyOptions,"rules":_vm.rules.required,"hideDetails":'auto'},model:{value:(_vm.importe),callback:function ($$v) {_vm.importe=$$v},expression:"importe"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"ml-n4 pl-7 pr-0",attrs:{"cols":"4"}},[(
                (_vm.esPVIV || _vm.esMovBanc) &&
                _vm.tipoValorSelected &&
                _vm.tipoValorSelected.esPropio
              )?_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","label":"A orden de"},model:{value:(_vm.aOrdenDe),callback:function ($$v) {_vm.aOrdenDe=$$v},expression:"aOrdenDe"}}):_vm._e()],1),_c('v-col',{staticClass:"text-right to-right pt-4 pr-0",attrs:{"cols":"8","align":"end"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.toggleNuevoTipoValor}},[_vm._v(" Cerrar ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"type":"submit","loading":_vm.loadingSave,"disabled":!_vm.isFormValid,"color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)],1),_c('ConfirmDialog',{attrs:{"text":_vm.textConfirm,"openConfirm":_vm.openConfirmDialog,"submitBtn":'SÍ',"cancelBtn":'NO'},on:{"update:openConfirm":function($event){_vm.openConfirmDialog = false},"onConfirm":_vm.confirmSaveValor}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }