var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"pt-1 pl-2 pb-0 primary--text text-h8"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.addDetalleAlicuotaIva($event)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pl-1 pr-1 pb-0",attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{ref:"conceptoNombre",attrs:{"hide-details":"auto","dense":"","autocomplete":"off","id":"conceptoNombre","outlined":"","rules":_vm.rules.required.concat([
                _vm.rules.requiredTrim(_vm.conceptoDetalleAlicuotaIva),
                _vm.rules.maxLength(_vm.conceptoDetalleAlicuotaIva, 50)
              ]),"label":"Concepto"},model:{value:(_vm.conceptoDetalleAlicuotaIva),callback:function ($$v) {_vm.conceptoDetalleAlicuotaIva=$$v},expression:"conceptoDetalleAlicuotaIva"}})],1),_c('v-col',{staticClass:"pt-0 pl-1 pr-1 pb-0",attrs:{"cols":"12","md":"5"}},[_c('v-autocomplete',{attrs:{"items":_vm.alicuotasIva,"outlined":"","rules":_vm.rules.required,"clearable":"","dense":"","item-text":"value","item-value":"id","label":"Alícuota IVA","return-object":"","hide-details":"auto"},on:{"input":function($event){return _vm.setIvaAlicuota(_vm.alicuotaIvaDetalleAlicuotaIva)}},model:{value:(_vm.alicuotaIvaDetalleAlicuotaIva),callback:function ($$v) {_vm.alicuotaIvaDetalleAlicuotaIva=$$v},expression:"alicuotaIvaDetalleAlicuotaIva"}})],1),_c('v-col',{staticClass:"pt-0 pl-1 pr-1 pb-0",attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"hide-details":"auto","dense":"","outlined":"","readonly":"","loading":_vm.loadingIva,"disabled":"","filled":"","label":"% IVA"},model:{value:(_vm.porcIvaDetalleAlicuotaIva),callback:function ($$v) {_vm.porcIvaDetalleAlicuotaIva=$$v},expression:"porcIvaDetalleAlicuotaIva"}})],1),_c('v-col',{staticClass:"pt-1 pb-0 pr-1 pl-1",attrs:{"cols":"12","md":"4"}},[_c('currency-input',{attrs:{"label":"Neto","options":_vm.currencyOptions,"filled":_vm.bloqueaIvaNeto,"disabled":_vm.bloqueaIvaNeto,"rules":_vm.bloqueaIvaNeto
                ? []
                : _vm.rules.required.concat([
                    _vm.rules.decimalGreaterThanZero(_vm.netoDetalleAlicuotaIva)
                  ]),"hideDetails":'auto'},on:{"change":function($event){return _vm.updateIvayTotalDetalleAlicuotaIva()}},model:{value:(_vm.netoDetalleAlicuotaIva),callback:function ($$v) {_vm.netoDetalleAlicuotaIva=$$v},expression:"netoDetalleAlicuotaIva"}})],1),_c('v-col',{staticClass:"pt-1 pb-0 pr-1 pl-1",attrs:{"cols":"12","md":"4"}},[_c('currency-input',{attrs:{"label":"IVA","disabled":_vm.bloqueaIvaNeto,"filled":_vm.bloqueaIvaNeto,"options":_vm.currencyOptions,"rules":[],"hideDetails":'auto'},on:{"change":function($event){return _vm.updateTotalDetalleAlicuotaIva()}},model:{value:(_vm.ivaDetalleAlicuotaIva),callback:function ($$v) {_vm.ivaDetalleAlicuotaIva=$$v},expression:"ivaDetalleAlicuotaIva"}})],1),_c('v-col',{staticClass:"pt-1 pb-0 pr-1 pl-1",attrs:{"cols":"12","md":"4"}},[_c('currency-input',{attrs:{"label":"Total","options":_vm.currencyOptions,"rules":[_vm.rules.decimalGreaterThanZero(_vm.totalDetalleAlicuotaIva)],"readonly":!_vm.bloqueaIvaNeto,"disabled":!_vm.bloqueaIvaNeto,"hideDetails":'auto'},model:{value:(_vm.totalDetalleAlicuotaIva),callback:function ($$v) {_vm.totalDetalleAlicuotaIva=$$v},expression:"totalDetalleAlicuotaIva"}})],1)],1),_c('v-card-actions',{staticClass:"pb-0 pt-1 pr-0 "},[_c('v-col',{staticClass:"text-right p-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","outlined":"","color":"error"},on:{"click":_vm.toggleNuevoDetalleAlicuotasIva}},[_vm._v("Cerrar")]),_c('v-btn',{attrs:{"small":"","type":"submit","disabled":!_vm.isFormValid || _vm.loadingIva,"color":"primary"}},[_vm._v("Guardar")])],1)],1),_c('v-row')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }