<template>
  <v-container fluid class="pt-0 px-0">
    <!-- Pago a proveedores -->
    <div class="backdrop" v-if="loadView">
      <div class="card-loader">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>

    <div v-if="!loadView">
      <v-row dense>
        <v-col cols="1" md="1" class="px-0 pt-2 pb-2">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="24"
                v-on="on"
                @click="volver(true)"
                tabindex="-1"
              >
                {{ volverIcon }}
              </v-icon>
            </template>
            <span>Atrás</span>
          </v-tooltip>
        </v-col>
        <v-col cols="11" md="8" class="pb-0 pt-0 px-0" tabindex="-1">
          <PageHeader :title="title" class="pt-0 pb-0" />
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="p-0 pt-2"
          v-if="numeroExpediente > 0"
          tabindex="0"
        >
          <span class="font-weight-medium text-right">
            Expediente N°:{{ numeroExpediente }}
          </span></v-col
        >
        <v-col
          cols="12"
          class="p-0 pb-2"
          v-if="editSoloImputacionNC || !canEditImputacion"
          tabindex="0"
        >
          <span class="error--text">{{ mensajeEditSoloImputacionNc }}</span>
        </v-col>
        <!-- <v-col
        cols="12"
        class="p-0 pb-2"
        v-if="fecContMenorFecCierre"
        tabindex="0"
      >
        <span class="error--text"
          >El comprobante no se puede modificar por estar cerrado el periodo
          contable.</span
        >
      </v-col> -->
      </v-row>
      <!-- Formulario -->
      <v-form v-model="isFormValid" ref="nc-form" id="nc-form">
        <v-row dense>
          <v-col cols="12" class="p-0">
            <v-card outlined>
              <v-card-text class="py-1 px-5">
                <v-row dense>
                  <v-col class="p-0" cols="12" md="4">
                    <v-autocomplete
                      v-model="empresaSelected"
                      return-object
                      :items="empresas"
                      outlined
                      item-value="id"
                      @change="
                        {
                          cleanArrays(),
                            getTiposComprobantes(),
                            setDatosEmpresa(),
                            setImputacionContableEmpresa(),
                            getLetraComprobanteByProvEntFacId();
                        }
                      "
                      :rules="rules.required"
                      :disabled="movProvId != null || editSoloImputacionNC"
                      tabindex="1"
                      :filled="movProvId != null || editSoloImputacionNC"
                      item-text="value"
                      clearable
                      ref="btnComboEmpresas"
                      id="btnComboEmpresas"
                      hide-details="auto"
                      dense
                      label="Empresa"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2" class="p-0 pl-1 ">
                    <v-text-field
                      type="text"
                      outlined
                      tabindex="2"
                      dense
                      hide-details="auto"
                      ref="codigoProveedor"
                      id="codigoProveedor"
                      label="Código proveedor"
                      @focus="bloqueaBtnAgregarComprobantes = true"
                      @blur="bloqueaBtnAgregarComprobantes = false"
                      :clearable="movProvId == null"
                      :disabled="movProvId != null || editSoloImputacionNC"
                      :filled="movProvId != null || editSoloImputacionNC"
                      v-model.trim="provCod"
                      :rules="
                        rules.required.concat([rules.maxLength(provCod, 10)])
                      "
                      @change="
                        {
                          cleanArrays(), buscarProveedor();
                        }
                      "
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pt-0 pr-4">
                    <v-row>
                      <v-col cols="12" md="1" class="pt-2 pl-4 pr-0">
                        <v-tooltip top max-width="50%">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              class="px-0"
                              v-bind="attrs"
                              v-on="on"
                              tabindex="-1"
                              @click="toggleModalBusquedaProv"
                              v-if="movProvId == null"
                            >
                              {{ searchIcon }}
                            </v-icon>
                          </template>
                          <span
                            >Buscar proveedor por nombre, CUIT o código</span
                          >
                        </v-tooltip>
                      </v-col>
                      <!-- DATOS PROVEEDOR -->

                      <v-col cols="12" md="8" class="p-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          disabled
                          :rules="rules.required"
                          label="Proveedor"
                          hide-details=""
                          :loading="proveedoresLoading"
                          v-model.trim="provNom"
                          autocomplete="off"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" class="p-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          disabled
                          label="Cond. IVA"
                          hide-details=""
                          :loading="proveedoresLoading"
                          v-model.trim="tipoIvaProvNom"
                          style="font-size: 0.7rem"
                          autocomplete="off"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6" class="pl-4 py-0 pr-3">
                    <v-row>
                      <v-col cols="12" md="6" class="p-0 pr-1">
                        <v-autocomplete
                          v-model="tipoComprobanteSelected"
                          :items="tiposComprobantes"
                          ref="tipoComprobante"
                          tabindex="3"
                          outlined
                          v-if="movProvId == null"
                          :clearable="movProvId == null"
                          :disabled="
                            empresaSelected == null ||
                              movProvId != null ||
                              editSoloImputacionNC
                          "
                          :filled="
                            empresaSelected == null ||
                              movProvId != null ||
                              editSoloImputacionNC
                          "
                          :loading="tiposComprobantesLoading"
                          :rules="rules.required"
                          item-value="id"
                          item-text="value"
                          dense
                          label="Tipo comprobante"
                          hide-details="auto"
                          @input="
                            {
                              setDatosTipoComprobante();
                            }
                          "
                        >
                        </v-autocomplete>
                        <v-text-field
                          outlined
                          v-else
                          dense
                          v-model="tipoComprobanteNom"
                          hide-details=""
                          label="Tipo comprobante"
                          :clearable="movProvId != null"
                          :disabled="movProvId != null"
                          :filled="movProvId != null"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" class="p-0 px-4">
                        <v-row>
                          <v-col class="p-0 " md="5">
                            <v-text-field
                              v-model="letraComprobante"
                              :rules="rules.required"
                              v-mask="'A'"
                              dense
                              tabindex="4"
                              outlined
                              hide-details="auto"
                              label="Letra"
                              @change="
                                getTipoCompNumAutomatico(
                                  tipoComprobanteSelected,
                                  letraComprobante,
                                  sucursalComprobante
                                )
                              "
                              :disabled="
                                parametroGeneralBloqueaLetraComp ||
                                  (tieneNumeradorAutomatico &&
                                    movProvId != null) ||
                                  editSoloImputacionNC
                              "
                              :filled="
                                parametroGeneralBloqueaLetraComp ||
                                  (tieneNumeradorAutomatico &&
                                    movProvId != null) ||
                                  editSoloImputacionNC
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col class="p-0 pl-1 " md="7">
                            <v-text-field
                              v-model="sucursalComprobante"
                              :rules="[
                                rules.decimalGreaterThanZero(
                                  parseInt(sucursalComprobante)
                                )
                              ]"
                              @input="
                                getTipoCompNumAutomatico(
                                  tipoComprobanteSelected,
                                  letraComprobante,
                                  sucursalComprobante
                                )
                              "
                              v-mask="'#####'"
                              dense
                              tabindex="5"
                              outlined
                              hide-details="auto"
                              label="Sucursal"
                              :disabled="
                                (tieneNumeradorAutomatico &&
                                  movProvId != null) ||
                                  editSoloImputacionNC
                              "
                              :filled="
                                (tieneNumeradorAutomatico &&
                                  movProvId != null) ||
                                  editSoloImputacionNC
                              "
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col class="p-0 pl-1 pr-1 " cols="4" md="3">
                        <v-text-field
                          v-model="numeroComprobante"
                          v-mask="'########'"
                          dense
                          outlined
                          hide-details="auto"
                          tabindex="6"
                          label="Número"
                          :rules="
                            tieneNumeradorAutomatico
                              ? []
                              : [
                                  rules.decimalGreaterThanZero(
                                    numeroComprobante
                                  )
                                ]
                          "
                          :filled="
                            tieneNumeradorAutomatico || editSoloImputacionNC
                          "
                          :disabled="
                            tieneNumeradorAutomatico || editSoloImputacionNC
                          "
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="px-4 py-0" cols="6" md="3">
                    <v-row dense>
                      <v-col cols="6" class="pr-0 py-0">
                        <v-menu
                          ref="fecha"
                          v-model="menuFecha"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fechaSelected"
                              label="Fecha"
                              hint="Formato DD/MM/AAAA"
                              @blur="
                                fecha = helpers.parseDateToIso(fechaSelected)
                              "
                              outlined
                              tabindex="7"
                              autocomplete="not"
                              hide-details="auto"
                              dense
                              clearable
                              v-mask="'##/##/####'"
                              v-bind="attrs"
                              v-on="on"
                              :rules="rules.required.concat(rules.validDate)"
                              :disabled="
                                !tipoCompEditaFecha || editSoloImputacionNC
                              "
                              :filled="
                                !tipoCompEditaFecha || editSoloImputacionNC
                              "
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fecha"
                            no-title
                            @change="fechaSelected = helpers.formatDate(fecha)"
                            @input="menuFecha = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6" class="pr-0 py-0">
                        <v-menu
                          ref="fechaContable"
                          v-model="menuFechaContable"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fechaContableSelected"
                              label="Fecha cble."
                              hint="Formato DD/MM/AAAA"
                              @blur="
                                fechaContable = helpers.parseDateToIso(
                                  fechaContableSelected
                                )
                              "
                              tabindex="8"
                              outlined
                              autocomplete="not"
                              hide-details="auto"
                              dense
                              clearable
                              v-mask="'##/##/####'"
                              v-bind="attrs"
                              v-on="on"
                              :rules="rules.required.concat(rules.validDate)"
                              :disabled="
                                !tipoCompEditaFecha || editSoloImputacionNC
                              "
                              :filled="
                                !tipoCompEditaFecha || editSoloImputacionNC
                              "
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fechaContable"
                            no-title
                            @change="
                              fechaContableSelected = helpers.formatDate(
                                fechaContable
                              )
                            "
                            @input="menuFechaContable = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- Concepto del pago -->
                  <v-col cols="12" md="3" class="py-0 pr-0">
                    <v-text-field
                      dense
                      tabindex="9"
                      :rules="[rules.maxLength(conceptoPago, 100)]"
                      v-model="conceptoPago"
                      outlined
                      :disabled="editSoloImputacionNC"
                      :filled="editSoloImputacionNC"
                      hide-details="auto"
                      label="Concepto del pago"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="2" class="p-0 pt-1 ">
                    <v-autocomplete
                      v-model="monedaProvSelected"
                      :items="monedas"
                      return-object
                      :disabled="bloqueaMoneda || true"
                      :filled="bloqueaMoneda || true"
                      v-if="parametroGeneralMultimoneda"
                      item-value="id"
                      item-text="value"
                      outlined
                      tabindex="10"
                      :rules="rules.required"
                      clearable
                      dense
                      label="Moneda prov."
                      hide-details=""
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2" class="p-0 pt-1 pl-1">
                    <v-autocomplete
                      v-model="monedaNcSelected"
                      :items="monedas"
                      @change="
                        monedaNcSelected
                          ? getCotizacion(monedaNcSelected.id)
                          : null
                      "
                      return-object
                      :disabled="true"
                      :filled="true"
                      v-if="parametroGeneralMultimoneda"
                      item-value="id"
                      item-text="value"
                      outlined
                      tabindex="11"
                      :rules="rules.required"
                      clearable
                      dense
                      label="Moneda NC"
                      hide-details="auto"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    v-if="showCotizacion && parametroGeneralMultimoneda"
                    class="pt-1 pr-4 pb-0"
                  >
                    <currency-input
                      v-model="cotizacion"
                      label="Cotización"
                      tabindex="12"
                      :disabled="
                        editSoloImputacionNC || comprobantes.length > 0
                      "
                      :filled="editSoloImputacionNC || comprobantes.length > 0"
                      :options="cotizacionOptions"
                      :rules="
                        showCotizacion
                          ? rules.required.concat([
                              rules.decimalGreaterThanZero(cotizacion)
                            ])
                          : []
                      "
                      :hideDetails="'auto'"
                      @change="calcularDiferenciaCambio(cotizacion)"
                    ></currency-input>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- Sumatorias comprobantes a cancelar -->
        <v-row>
          <v-col cols="12" md="12" class="p-0  py-1">
            <v-card outlined class="p-3 pt-2">
              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0 pl-2">
                  <span class="primary--text font-weight-medium py-0 pl-2"
                    >Totales nota de crédito</span
                  >
                </v-col>

                <v-col cols="12" md="2" class="pt-1 pb-1 px-1">
                  <currency-input
                    v-model="netoNc"
                    tabindex="13"
                    label="Neto"
                    :disabled="editSoloImputacionNC"
                    :filled="editSoloImputacionNC"
                    @change="updateTotalNc()"
                    :options="currencyOptions"
                    :rules="rules.required"
                    :hideDetails="'true'"
                  ></currency-input>
                </v-col>
                <v-col class="pt-1 pb-1  px-1">
                  <currency-input
                    v-model="ivaNc"
                    tabindex="14"
                    @change="updateTotalNc()"
                    :disabled="editSoloImputacionNC"
                    :filled="editSoloImputacionNC"
                    label="IVA"
                    :options="currencyOptions"
                    :rules="rules.required"
                    :hideDetails="'true'"
                  ></currency-input>
                </v-col>
                <v-col class="pt-1 pb-1 px-1">
                  <currency-input
                    v-model="percepIvaNc"
                    label="Percepción IVA"
                    :disabled="letraComprobante == 'C' || editSoloImputacionNC"
                    @change="updateTotalNc()"
                    :filled="letraComprobante == 'C' || editSoloImputacionNC"
                    :tabindex="'15'"
                    :options="currencyOptions"
                    :rules="rules.required"
                    :hideDetails="'true'"
                  ></currency-input>
                </v-col>
                <v-col class="pt-1 pb-1 px-1" v-if="!empresaMultijurisdiccion">
                  <currency-input
                    v-model="percepIbNc"
                    label="Percep. IIBB"
                    :options="currencyOptions"
                    :tabindex="'16'"
                    :readonly="false"
                    @change="updateTotalNc()"
                    :disabled="editSoloImputacionNC"
                    :filled="editSoloImputacionNC"
                    :rules="!empresaMultijurisdiccion ? rules.required : []"
                    :hideDetails="'true'"
                  ></currency-input>
                </v-col>
                <v-col class="pt-1 pb-1 pl-2 px-0" v-else>
                  <currency-input
                    v-model="totalPercIIBB"
                    label="Percep. IIBB"
                    :options="currencyOptions"
                    :readonly="true"
                    :disabled="true"
                    :filled="true"
                    :rules="[]"
                    :hideDetails="'true'"
                  ></currency-input>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                  class="pt-2  pl-1 pr-1"
                  v-if="empresaMultijurisdiccion"
                >
                  <v-btn
                    x-small
                    ref="percIIBB"
                    tabindex="17"
                    icon
                    @click="toggleModalPercIIBB"
                    :disabled="
                      provId == null || entFacId == null || editSoloImputacionNC
                    "
                    v-if="empresaMultijurisdiccion"
                  >
                    <v-icon>
                      {{ editIcon }}
                    </v-icon>
                  </v-btn>
                </v-col>

                <v-col class="pt-1 pb-1  px-1">
                  <currency-input
                    v-model="impInternosNc"
                    :tabindex="'18'"
                    :id="'impInternosNc'"
                    @change="updateTotalNc()"
                    :disabled="editSoloImputacionNC"
                    :filled="editSoloImputacionNC"
                    label="Imp. Internos"
                    :options="currencyOptions"
                    :rules="rules.required"
                    :hideDetails="'true'"
                  ></currency-input>
                </v-col>
                <v-col class="pt-1 pb-1  px-1">
                  <currency-input
                    v-model="otrosConceptosNc"
                    label="Otros conceptos"
                    :tabindex="'19'"
                    @change="updateTotalNc()"
                    :disabled="editSoloImputacionNC"
                    :filled="editSoloImputacionNC"
                    :options="currencyOptions"
                    :rules="rules.required"
                    :hideDetails="'true'"
                  ></currency-input>
                </v-col>
                <v-col cols="12" md="2" class="pt-1 pb-1  px-1">
                  <currency-input
                    v-model="totalNc"
                    label="Total"
                    :tabindex="'20'"
                    :options="currencyOptions"
                    :rules="rules.required"
                    @input="bloqueaDistribuirComprobantes = false"
                    :hideDetails="'true'"
                    :disabled="editSoloImputacionNC"
                    :filled="editSoloImputacionNC"
                  ></currency-input>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- Comprobantes a cancelar -->
        <v-row dense>
          <v-col cols="12" class="p-0">
            <v-card outlined class="pb-0">
              <v-row>
                <v-col cols="12" md="10" class="pt-2 pb-0 pl-6">
                  <span class="primary--text font-weight-medium py-0 pl-2"
                    >Comprobantes a cancelar</span
                  >
                </v-col>

                <v-col cols="12" md="2" class=" pt-2 pb-0">
                  <v-btn
                    small
                    outlined
                    @click="distribuirComprobantes()"
                    color="primary"
                    :disabled="
                      !canEditImputacion ||
                        comprobantes.some(
                          x => x.tieneDifCambioConAsientoCont
                        ) ||
                        ((!comprobantes.length > 0 ||
                          bloqueaDistribuirComprobantes) &&
                          !editSoloImputacionNC)
                    "
                    >Distribuir</v-btn
                  >
                </v-col>
              </v-row>
              <v-card-text class="py-1">
                <v-data-table
                  :headers="filteredHeadersComp"
                  :items="comprobantes"
                  v-show="!monedaProveedorLoading"
                  :search="search"
                  dense
                >
                  <template v-slot:top>
                    <v-row dense>
                      <v-col cols="12" md="4" class="py-0 pl-6">
                        <v-text-field
                          v-model="search"
                          :append-icon="searchIcon"
                          label="Buscar"
                          dense
                          outlied
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="8" class="px-2 pr-0 pt-1 text-right">
                        <v-btn
                          small
                          class="px-4 mx-2"
                          outlined
                          :disabled="
                            !comprobantes.length > 0 || !canEditImputacion
                          "
                          color="primary"
                          @click="quitarComprobantes()"
                          >Quitar todos</v-btn
                        >

                        <v-btn
                          small
                          class="px-4 mx-2"
                          :disabled="!comprobantes.length > 0"
                          outlined
                          @click="exportComprobantes()"
                          color="primary"
                          >Exportar</v-btn
                        >

                        <v-btn
                          small
                          class="pl-4 mx-2 "
                          color="primary"
                          tabindex="21"
                          @click="buscarComprobantesCancelar = true"
                          :disabled="
                            bloqueaBtnAgregarComprobantes ||
                              proveedorSelected == null ||
                              empresaSelected == null ||
                              tipoComprobanteSelected == null ||
                              !canEditImputacion
                          "
                          >Agregar comprobantes</v-btn
                        >
                      </v-col>
                      <v-col
                        cols="12"
                        class="p-0 pl-6"
                        v-if="
                          comprobantes.some(x => x.tieneDifCambioConAsientoCont)
                        "
                        tabindex="0"
                      >
                        <span class="error--text"
                          >Hay imputaciones que no pueden ser modificadas por
                          tener comprobantes de dif. de cambio generados con
                          asiento contable.</span
                        >
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:[`item.total`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(item.total, "", true)
                    }}</span>
                  </template>
                  <template v-slot:[`item.saldo`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(item.saldo, "", true)
                    }}</span>
                  </template>

                  <template v-slot:[`item.saldoComp`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(item.saldoComp, "", true)
                    }}</span>
                  </template>
                  <template v-slot:[`item.cancelado`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(item.cancelado, "", true)
                    }}</span>
                  </template>
                  <!-- <template v-slot:[`item.canceladoComp`]="{ item }">
                <span>{{
                  helpers.floatToMoneyString(item.canceladoComp, "", true)
                }}</span>
              </template> -->
                  <template v-slot:[`item.cancela`]="{ item }">
                    <currency-input
                      v-model="item.cancela"
                      dense
                      hide-details="auto"
                      class="to-right right-input"
                      style="
                      max-width: 125px;
                      font-size: 12px;
                      width: 120px;
                      display: inline-block;
                    "
                      outlined
                      :rules="[
                        rules.decimalValidRanges(item.cancela, 0, item.saldo),
                        rules.decimalGreaterThanZero(item.cancela)
                      ]"
                      tabindex="22"
                      :options="currencyOptions"
                    ></currency-input>
                  </template>

                  <template v-slot:[`item.cancelaComp`]="{ item }">
                    <currency-input
                      v-model="item.cancelaComp"
                      dense
                      hide-details="auto"
                      class="to-right right-input"
                      outlined
                      :disabled="
                        item.tieneDifCambioConAsientoCont || !canEditImputacion
                      "
                      :filled="
                        item.tieneDifCambioConAsientoCont || !canEditImputacion
                      "
                      tabindex="22"
                      style="
                      max-width: 125px;
                      font-size: 12px;
                      width: 120px;
                      display: inline-block;
                    "
                      :rules="[
                        rules.decimalValidRanges(
                          item.cancelaComp,
                          0,
                          item.saldoComp
                        ),
                        rules.decimalGreaterThanZero(item.cancelaComp)
                      ]"
                      @change="calcularDiferenciaCambio(cotizacion)"
                      :options="currencyOptions"
                    ></currency-input>
                  </template>
                  <template v-slot:[`item.cotizacionComprobante`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(
                        item.cotizacionComprobante,
                        "",
                        true
                      )
                    }}</span>
                  </template>

                  <template v-slot:[`item.diferenciaCambio`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(
                        item.diferenciaCambio,
                        siglaMonedaPorDefecto,
                        false
                      )
                    }}</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip left max-width="25rem" v-if="showCotizacion">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon size="20" v-on="on" v-bind="attrs">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <v-row>
                        <v-col cols="12" class="p-0">
                          <span>
                            (Cancela * Cotiz. N.C) - (Cancela * Cotiz. Fact.)
                          </span>
                        </v-col>
                        <v-col cols="12" class="p-0">
                          <span>
                            {{ calculoDiferenciaDeCambio(item) }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          size="20"
                          v-on="on"
                          @click="goToVerComprobante(item)"
                        >
                          {{ seeIcon }}
                        </v-icon>
                      </template>
                      <span>Ver comprobante</span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          size="20"
                          :disabled="
                            !canEditImputacion ||
                              item.tieneDifCambioConAsientoCont
                          "
                          v-on="on"
                          @click="
                            comprobantes = comprobantes.filter(x => x !== item)
                          "
                        >
                          {{ deleteIcon }}
                        </v-icon>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- Detalle alicuotas e imputacion contable -->
        <v-row>
          <v-col
            cols="12"
            :md="tipoCompLlevaImpContable ? 7 : 12"
            class="pl-4 pt-1 pr-4"
          >
            <!-- DETALLE ALICUOTAS IVA -->
            <v-row>
              <v-col cols="12" class="p-0">
                <v-data-table
                  :headers="detalleAlicuotasIvaHeaders"
                  :items="detalleAlicuotasIvaItems"
                  height="100%"
                  fixed-header
                  class="elevation-1"
                  :items-per-page="100"
                  hide-default-footer
                  dense
                  item-key="id"
                >
                  <template v-slot:top>
                    <v-row>
                      <v-col cols="12" md="6" class="pb-0 pt-2 ">
                        <span class="primary--text font-weight-medium py-0 pl-4"
                          >Detalle Alícuotas IVA</span
                        >
                      </v-col>

                      <v-col cols="12" md="6" class="pb-0 pt-2 pr-6 text-right">
                        <v-btn
                          ref="btnToggleNuevoDetalleAlicuotasIva"
                          small
                          @click="toggleNuevoDetalleAlicuotasIva"
                          tabindex="23"
                          :disabled="
                            tipoComprobanteSelected == null ||
                              editSoloImputacionNC
                          "
                          class="py-0"
                          color="primary"
                        >
                          Nuevo
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          size="20"
                          v-on="on"
                          :disabled="editSoloImputacionNC"
                          @click="editDetalleAlicuota(item)"
                        >
                          {{ editIcon }}
                        </v-icon>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          size="20"
                          :disabled="editSoloImputacionNC"
                          v-on="on"
                          @click="deleteDetalleAlicuota(item)"
                        >
                          {{ deleteIcon }}
                        </v-icon>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.neto`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(item.neto, "", true)
                    }}</span>
                  </template>
                  <template v-slot:[`item.iva`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(item.iva, "", true)
                    }}</span>
                  </template>
                  <template v-slot:[`item.total`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(item.total, "", true)
                    }}</span>
                  </template>
                  <template v-slot:footer>
                    <v-row>
                      <v-col cols="12" md="4" class="pt-1 pb-2 pl-5  ">
                        <span
                          style="font-size: 0.8rem"
                          class="font-weight-medium "
                        >
                          Neto:
                          {{ netoDetalleAlicuotasIvaString }}
                        </span>
                      </v-col>
                      <v-col cols="12" md="4" class="pt-1 pb-2   ">
                        <span
                          style="font-size: 0.8rem"
                          class="font-weight-medium "
                        >
                          IVA:
                          {{ ivaDetalleAlicuotasIvaString }}</span
                        >
                      </v-col>
                      <v-col cols="12" md="4" class="pt-1 pb-2  ">
                        <span
                          style="font-size: 0.8rem"
                          class="font-weight-medium "
                        >
                          Total:
                          {{ totalDetalleAlicuotasIvaString }}
                        </span>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
          <!-- BARRA LATERAL DERECHA  -->
          <v-col
            cols="12"
            md="5"
            class="p-0 pt-1 pl-5 pr-4"
            v-if="tipoCompLlevaImpContable"
          >
            <v-card class="p-0">
              <v-row>
                <!-- IMPUTACION CONTABLE -->
                <v-col cols="12" md="12" class="p-0 ">
                  <v-data-table
                    :headers="imputacionContableHeaders"
                    :items="imputacionContableItems"
                    fixed-header
                    class="elevation-1"
                    hide-default-footer
                    :items-per-page="100"
                    dense
                    item-key="id"
                  >
                    <template v-slot:top>
                      <v-row>
                        <v-col cols="12" md="6" class="pb-0 pt-2">
                          <span
                            class="primary--text py-0 pl-2 font-weight-medium"
                            >Imputación contable</span
                          >
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                          class="pb-0 pt-2 pr-6 text-right"
                        >
                          <!-- <v-btn
                            small
                            @click="toggleNuevoImputacionContable"
                            class="py-0"
                            color="primary"
                            tabindex="24"
                            :disabled="
                              datosImputacionContable == null ||
                                editSoloImputacionNC
                            "
                          >
                            {{
                              imputacionContableItems.length > 0 ||
                              centroCostosItems.length > 0
                                ? "Modificar"
                                : "Nuevo"
                            }}
                          </v-btn> -->
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="pb-0 pt-1 pl-6 text-right"
                        >
                          <v-btn
                            small
                            @click="setImputacionesContablesACancelar(false)"
                            tabindex="-1"
                            :disabled="
                              tipoComprobanteSelected == null ||
                                !comprobantes.length > 0 ||
                                editSoloImputacionNC
                            "
                            class="py-0 px-1"
                            color="primary"
                            outlined
                          >
                            Cargar imput. de comp. cancelados
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="pb-0 pt-1 px-6 text-right"
                        >
                          <v-btn
                            small
                            @click="toggleNuevoImputacionContable"
                            class="py-0"
                            color="primary"
                            tabindex="24"
                            :disabled="
                              datosImputacionContable == null ||
                                editSoloImputacionNC
                            "
                          >
                            {{
                              imputacionContableItems.length > 0 ||
                              centroCostosItems.length > 0
                                ? "Modificar"
                                : "Nuevo"
                            }}
                          </v-btn>
                          <!-- <v-btn
                            small
                            @click="setImputacionesContablesACancelar(true)"
                            tabindex="-1"
                            :disabled="
                              tipoComprobanteSelected == null ||
                                !comprobantes.length > 0 ||
                                editSoloImputacionNC
                            "
                            class="py-0 px-2"
                            color="primary"
                            outlined
                          >
                            Distribuir
                          </v-btn> -->
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`item.importe`]="{ item }">
                      <span>{{
                        helpers.floatToMoneyString(item.importe, "", true)
                      }}</span>
                    </template>
                    <template v-slot:footer>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-1 pb-2 pl-0 pr-6 text-right"
                        >
                          <span
                            style="font-size: 0.8rem"
                            class="font-weight-medium text-right"
                          >
                            Total imputable:
                            {{
                              helpers.floatToMoneyString(
                                totalImputable,
                                monedaNcSelected != null &&
                                  monedaNcSelected.id != monedaPorDefecto
                                  ? siglaMonedaPorDefecto
                                  : ""
                              )
                            }}</span
                          >
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-1 pb-2 pl-0 pr-6 text-right"
                        >
                          <span
                            style="font-size: 0.8rem"
                            class="font-weight-medium text-right"
                          >
                            Total imputación contable:
                            {{
                              helpers.floatToMoneyString(
                                totalImputacionContable,
                                monedaNcSelected != null &&
                                  monedaNcSelected.id != monedaPorDefecto
                                  ? siglaMonedaPorDefecto
                                  : ""
                              )
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-col>
                <!-- CENTRO DE COSTOS -->
                <v-col cols="12" md="12" class="p-0 ">
                  <v-data-table
                    :headers="centroCostosHeaders"
                    :items="centroCostosItems"
                    height="100%"
                    :items-per-page="100"
                    fixed-header
                    class="elevation-1"
                    hide-default-footer
                    dense
                    item-key="id"
                  >
                    <template v-slot:top>
                      <v-row>
                        <v-col cols="12" md="6" class="pt-2 pb-0">
                          <span
                            class="primary--text font-weight-medium py-0 pl-2"
                            >Centros de costos</span
                          >
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`item.importe`]="{ item }">
                      <span>{{
                        helpers.floatToMoneyString(item.importe, "", true)
                      }}</span>
                    </template>
                    <template v-slot:footer>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-1 pb-2 pl-0 pr-6 text-right"
                        >
                          <span
                            style="font-size: 0.8rem"
                            class="font-weight-medium text-right"
                          >
                            Gastos a imputar:
                            {{
                              helpers.floatToMoneyString(
                                totalGastoAImputar,
                                monedaNcSelected != null &&
                                  monedaNcSelected.id != monedaPorDefecto
                                  ? siglaMonedaPorDefecto
                                  : ""
                              )
                            }}</span
                          >
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-1 pb-2 pl-0 pr-6 text-right"
                        >
                          <span
                            style="font-size: 0.8rem"
                            class="font-weight-medium text-right"
                          >
                            Total centros de costos:
                            {{
                              helpers.floatToMoneyString(
                                totalCentrosDeCostos,
                                monedaNcSelected != null &&
                                  monedaNcSelected.id != monedaPorDefecto
                                  ? siglaMonedaPorDefecto
                                  : ""
                              )
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <!--Boton guardar -->
        <v-row>
          <v-col cols="12" class="pt-4 text-right" align="end">
            <v-btn
              :loading="loadingSaveBtn"
              :disabled="
                !isFormValid ||
                  loadingSaveBtn ||
                  !valorNcValido ||
                  !canEditImputacion
              "
              tabindex="25"
              @click="saveNotaCredito()"
              color="primary"
              >Guardar</v-btn
            >
          </v-col>
        </v-row>
      </v-form>

      <!-- MODAL PERCEPCIONES IIBB -->

      <v-dialog
        v-model="modalPercIIBB"
        v-if="modalPercIIBB"
        @keydown.esc="toggleModalPercIIBB"
        max-width="50%"
        persistent
      >
        <v-container fluid class="p-0">
          <v-card class="p-2 pr-4">
            <v-row>
              <v-col cols="12" class="pl-4 py-0">
                <span class="primary--text py-0 pl-2 font-weight-medium"
                  >Percepciones de IIBB</span
                >
              </v-col>
            </v-row>
            <v-card-text>
              <v-form
                v-model="isFormPercIIBBValid"
                ref="jurisdiccionesIIBBForm"
              >
                <v-row>
                  <v-col cols="12" md="7" class="px-2 pt-0 pb-2">
                    <v-autocomplete
                      v-model="selectedJurisdiccion"
                      :items="jurisdicciones"
                      item-value="jurIbId"
                      item-text="jurIbNombre"
                      label="Jurisdicción IIBB"
                      outlined
                      return-object
                      dense
                      :rules="rules.required"
                      :hide-details="true"
                      clearable
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" class="px-2 py-0">
                    <currency-input
                      v-model="importeItemPercIIBB"
                      label="Importe"
                      :options="currencyOptions"
                      :rules="[
                        rules.decimalGreaterThanZero(importeItemPercIIBB)
                      ]"
                      :readonly="false"
                      :hideDetails="'true'"
                    ></currency-input>
                  </v-col>
                  <v-col cols="2" md="2" class="px-2 pt-1 pb-0 text-right">
                    <v-btn
                      small
                      color="primary"
                      form="jurisdiccionesIIBBForm"
                      @click="addJurisdiccion()"
                      type="submit"
                      :disabled="!isFormPercIIBBValid"
                    >
                      {{ internIdPercIIBB != null ? "Guardar" : "Agregar" }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-data-table
                :headers="headersPercIIBB"
                :items="itemsPercIIBB"
                item-key="id"
                class="elevation-1"
                hide-default-footer
                dense
              >
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="editPercIIBB(item)"
                      >
                        {{ editIcon }}
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        :disabled="internIdPercIIBB == item.id"
                        size="20"
                        v-on="on"
                        @click="removeItem(item)"
                      >
                        {{ deleteIcon }}
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.importe`]="{ item }">
                  <span>{{ helpers.floatToMoneyString(item.importe) }}</span>
                </template>
                <template v-slot:footer>
                  <v-row>
                    <v-col cols="12" md="6" class="py-0 pl-0 pr-6 text-right">
                      <span class="font-weight-medium text-right">
                        Total :
                        {{ helpers.floatToMoneyString(totalPercIIBB) }}</span
                      >
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-row>
                <v-col cols="12" class="py-0 pr-4 text-right">
                  <v-btn color="primary" small @click="toggleModalPercIIBB">
                    Salir
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-dialog>
      <!-- MODAL BUSCAR PROVEEDOR -->
      <v-dialog
        v-model="buscarProveedorModal"
        v-if="buscarProveedorModal"
        @keydown.esc="toggleModalBusquedaProv"
        max-width="70%"
        persistent
      >
        <BuscarProveedor
          @toggleModalBusquedaProv="toggleModalBusquedaProv"
          @findProveedor="findProveedor"
        ></BuscarProveedor>
      </v-dialog>
      <!-- MODAL NUEVO DETALLE ALICUOTAS IVA -->
      <v-dialog
        v-model="detalleAlicuotasIvaModal"
        v-if="detalleAlicuotasIvaModal"
        @keydown.esc="toggleNuevoDetalleAlicuotasIva"
        max-width="35%"
        persistent
      >
        <EditDetalleAlicuotasIva
          :detalleAlicuotaToEdit="detalleAlicuotaToEdit"
          :itemsCount="itemsCount"
          :fechaComp="fecha"
          :letraComp="letraComprobante"
          @toggleNuevoDetalleAlicuotasIva="toggleNuevoDetalleAlicuotasIva"
          @addDetalleAlicuotaIva="addDetalleAlicuotaIva"
          @editDetalleAlicuotaIva="editDetalleAlicuotaIva"
        ></EditDetalleAlicuotasIva>
      </v-dialog>

      <!-- MODAL NUEVA IMPUTACION CONTABLE -->
      <v-dialog
        v-model="imputacionContableModal"
        v-if="imputacionContableModal"
        @keydown.esc="toggleNuevoImputacionContable"
        max-width="50rem"
        persistent
      >
        <ImputacionCbleCentroCostos
          :EntFacId="entFacId"
          :empresaIdConta="empresaIdConta"
          :ProvId="provId"
          :datosImputacionContable="datosImputacionContable"
          :totalImputable="totalImputable"
          :imputacionContableItemsProp="imputacionContableItemsProp"
          :centroCostosItemsProp="centroCostosItemsProp"
          @toggleNuevoImputacionContable="toggleNuevoImputacionContable"
          @reloadTables="reloadTablesImputacionContable"
        ></ImputacionCbleCentroCostos>
      </v-dialog>
      <!-- MODAL BUSCAR COMPROBANTES A CANCELAR -->
      <v-dialog
        v-model="buscarComprobantesCancelar"
        v-if="buscarComprobantesCancelar"
        @keydown.esc="toggleModalComprobantesCancelar"
        max-width="75rem"
        persistent
      >
        <BusquedaComprobantesCancelar
          :empresa="empresaSelected"
          :proveedor="proveedorSelected"
          :monedaProv="monedaProvSelected"
          :monedaOP="monedaNcSelected"
          :showDiferenciaCambio="showCotizacion"
          :cotizacionActual="cotizacion ? cotizacion : 0"
          :esNotaCredito="true"
          :tipoIvaProveedor="tipoIvaProv"
          :movProvId="movProvId"
          @toggleModalComprobantesCancelar="toggleModalComprobantesCancelar"
          @setComprobantesCancelar="setComprobantesCancelar"
        ></BusquedaComprobantesCancelar>
      </v-dialog>
      <!-- DIALOG VER COMPROBANTE -->
      <v-dialog
        v-if="openSeeComp"
        v-model="openSeeComp"
        max-width="80%"
        @keydown.esc="openSeeComp = false"
        persistent
      >
        <DetalleComprobanteProvDialog
          @closeModalDelete="openSeeComp = false"
          :paramsToSeeCompDialog="paramsToSeeComp"
          :isDelete="false"
        ></DetalleComprobanteProvDialog>
      </v-dialog>
      <!-- CONFIRM DIALOG NC EN CERO -->
      <ConfirmDialog
        :openConfirm.sync="confirmDialogNcEnCero"
        :text="confirmDialogText"
        :maxWidth="'45rem'"
        :title="'¿Desea grabar una nota de crédito con importe en cero?'"
        @onConfirm="toggleConfirmDialogNotaCreditoEnCero(true)"
      ></ConfirmDialog>
      <!-- ConfirmDialog -->
      <v-dialog
        v-model="confirmDialogExpediente"
        max-width="400"
        @keydown.esc="toggleConfirmDialog()"
        persistent
      >
        <v-card>
          <v-card-title class="headline primary--text">Atención</v-card-title>
          <v-card-text class="text-left">{{ confirmDialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="volver(true)"> NO</v-btn>
            <v-btn text color="primary" dark @click="toggleConfirmDialog()"
              >SI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import helpers from "@/utils/helpers.js";
import helpersExcel from "@/utils/helpers/importExportExcel";
import GoBackBtn from "@/components/shared/GoBackBtn";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import BuscarProveedor from "@/components/modules/proveedores/BuscarProveedor.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import BusquedaComprobantesCancelar from "@/components/modules/proveedores/ordenesPago/BusquedaComprobantesCancelar.vue";
import NuevoTipoValor from "@/components/modules/proveedores/ordenesPago/NuevoTipoValor.vue";
import ImputacionCbleCentroCostos from "@/views/modules/proveedores/cuentaCorriente/ImputacionCbleCentroCostosCargaFactura.vue";
import EditDetalleAlicuotasIva from "@/views/modules/proveedores/cuentaCorriente/EditDetalleAlicuotasIva.vue";
import DetalleComprobanteProvDialog from "@/components/modules/proveedores/cuentaCorriente/DetalleComprobanteProvDialog.vue";

export default {
  name: "NuevaNotaDeCredito",
  directives: { mask },
  components: {
    PageHeader,
    Ayuda,
    GoBackBtn,
    BuscarProveedor,
    CurrencyInput,
    ConfirmDialog,
    BusquedaComprobantesCancelar,
    EditDetalleAlicuotasIva,
    ImputacionCbleCentroCostos,
    NuevoTipoValor,
    DetalleComprobanteProvDialog
  },
  data() {
    return {
      movProvId: null,
      title: "Nota de crédito",
      confirmDialogText: "",
      enums: enums,
      rules: rules,
      helpers: helpers,
      routeToGo: null,
      search: null,
      loadingSaveBtn: false,
      isFormValid: false,
      loadView: true,
      searchIcon: enums.icons.SEARCH,
      seeIcon: enums.icons.SEE,
      editIcon: enums.icons.EDIT,
      volverIcon: enums.icons.BACK,
      infoIcon: enums.icons.SNB_INFO,
      deleteIcon: enums.icons.DELETE,
      openConfirmDialog: false,
      confirmDialogNcEnCero: false,
      bloqueaDistribuirComprobantes: false,
      flagConfirmNcEnCero: false,
      openSeeComp: false,
      paramsToSeeComp: {},
      editSoloImputacionNC: false,
      canEditImputacion: true,
      mensajeEditSoloImputacionNc: "",
      // fechas
      fecha: null,
      menuFecha: null,
      fechaSelected: null,
      fechaContable: null,
      menuFechaContable: null,
      fechaContableSelected: null,
      currencyOptions: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
        valueRange: {
          min: 0,
          max: 9999999999
        },
        precision: undefined,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true
      },
      cotizacionOptions: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
        valueRange: {
          min: 0,
          max: 99999999
        },
        precision: { min: 0, max: 2 },
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true
      },
      //IMPUTACION CONTABLE Y CENTRO DE COSTOS
      empresaMultijurisdiccion: false,
      detalleAlicuotasIvaModal: false,
      datosImputacionContable: null,
      imputacionContableModal: false,
      modalPercIIBB: false,
      isFormPercIIBBValid: false,
      detalleAlicuotaToEdit: null,
      selectedJurisdiccion: null,
      jurisdicciones: [],
      headersPercIIBB: [
        { text: "Jurisdicción", value: "jurIbNombre", align: "start" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Acciones", value: "acciones", align: "end", sortable: false }
      ],
      itemsPercIIBB: [],
      importeItemPercIIBB: null,
      internIdPercIIBB: null,
      itemsCount: null,
      totalCentrosDeCostos: 0,
      totalImputacionContable: 0,
      totalGastoAImputar: 0,
      tipoCompLlevaImpContable: false,
      tipoCompEditaFecha: true,
      tCompSigno: null,

      empresas: [],
      itemsPerPage: 10,
      empresaSelected: null,

      proveedores: [],

      confirmDialogExpediente: false,
      tieneAsientoContable: false,
      fecContMenorFecCierre: false,
      numeroExpediente: null,

      tipoIvaProv: null,
      tipoIvaProvNom: null,
      conceptoPago: null,
      provCod: null,
      proveedorSelected: null,
      proveedoresLoading: null,
      monedaProveedorLoading: false,
      bloqueaBtnAgregarComprobantes: false,
      provId: null,
      provNom: null,
      buscarProveedorModal: false,
      buscarComprobantesCancelar: false,
      cuit: null,
      monedaNcSelected: null,
      monedaProvSelected: null,
      monedaPorDefecto: null,
      siglaMonedaPorDefecto: null,
      monedaPorDefectoNom: null,
      letraComprobante: null,
      letraComprobanteIvaRela: null,
      sucursalComprobante: null,
      numeroComprobante: null,
      // PARAMETROS GENERALES
      permitirCargarNCCeroProv: false,
      parametroGeneralMultimoneda: false,
      parametroGeneralBloqueaLetraComp: false,
      validarFecCbleFacProv: false,
      imputCbleIVA: false,
      imputCbleImpInt: false,
      imputCbleOtros: false,
      bloqueaMoneda: false,
      tipoComprobanteSelected: null,
      tieneNumeradorAutomatico: false,
      tiposComprobantesLoading: false,
      tipoComprobanteNom: null,
      tiposComprobantes: [],
      monedas: [],
      cotizacionLoading: false,
      cotizacion: 0,
      //totales comprobantes
      netoNc: 0.0,
      ivaNc: 0.0,
      percepIvaNc: 0.0,
      percepIbNc: 0.0,
      impInternosNc: 0.0,
      otrosConceptosNc: 0.0,
      totalNc: 0,

      //#region HEADERS Y ARRAYS
      // data table headers
      imputacionContableHeaders: [
        {
          text: "Cuenta",
          sortable: false,
          value: "cuenta",
          align: "start"
        },
        {
          text: "Descripción",
          sortable: false,
          value: "descripcion",
          align: "start"
        },
        {
          text: "Importe",
          sortable: false,
          value: "importe",
          align: "end"
        }
      ],
      centroCostosHeaders: [
        {
          text: "Nombre",
          sortable: false,
          value: "nombre",
          align: "start"
        },
        {
          text: "Importe",
          sortable: false,
          value: "importe",
          align: "end"
        }
      ],
      detalleAlicuotasIvaHeaders: [
        {
          text: "Concepto",
          sortable: false,
          value: "concepto",
          align: "start"
        },
        {
          text: "Alícuota IVA",
          sortable: false,
          value: "alicuotaIVA",
          align: "start"
        },
        {
          text: "% IVA",
          sortable: false,
          value: "ivaPorcentaje",
          align: "end"
        },
        {
          text: "Neto",
          sortable: false,
          value: "neto",
          align: "end"
        },
        {
          text: "IVA",
          sortable: false,
          value: "iva",
          align: "end"
        },
        {
          text: "Total",
          sortable: false,
          value: "total",
          align: "end"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          width: "12%",
          align: "end"
        }
      ],
      headersComprobantes: [
        { text: "Comprobante", value: "nroComp", sortable: false },
        { text: "Fecha", value: "fechaComprobanteFormatted", sortable: false },
        {
          text: "Vencimiento",
          value: "fechaVencimientoFormatted",
          sortable: false
        },
        { text: "Total", align: "end", value: "total", sortable: false },
        {
          text: "Cancelado",
          align: "end",
          value: "cancelado",
          sortable: false
        },
        // { text: "Cancelado", align: "end", value: "canceladoComp" },
        { text: "Saldo", align: "end", value: "saldo", sortable: false },
        { text: "Saldo", align: "end", value: "saldoComp", sortable: false },
        { text: "Cancela", align: "end", value: "cancela", sortable: false },
        {
          text: "Cancela",
          align: "end",
          value: "cancelaComp",
          sortable: false
        },
        // { text: "Cotiz.", align: "end", value: "cotizacionComprobante" },
        {
          text: "Dif. Cambio",
          align: "end",
          value: "diferenciaCambio",
          sortable: false
        },
        { text: "Acciones", align: "end", value: "actions", sortable: false }
      ],

      // data table arrays
      detalleAlicuotasIvaItems: [],
      imputacionContableItems: [],
      centroCostosItems: [],
      imputacionContableItemsProp: [],
      centroCostosItemsProp: [],
      comprobantes: []
      //#endregion
    };
  },
  async created() {
    await this.setParametrosGeneralesProveedores();
    this.movProvId = this.$route.params.movProvId ?? null;

    await this.setCombos();
    if (this.movProvId != null) {
      const response = await this.canEditTotalNc(this.movProvId);
      if (response != null) {
        this.mensajeEditSoloImputacionNc = response.mensaje;

        this.editSoloImputacionNC = !response.canEditTotal;

        this.canEditImputacion = response.canEditImputacion;
        // this.setAlert({ type: "warning", message: response });
      }
      await this.setDatosNotaCreditoEdit();
    } else {
      this.setFechas();
      await this.setEmpresaProveedor(
        this.$route.params.empresaSelected,
        this.$route.params.provCod
      );
    }
    this.loadView = false;

    this.routeToGo = this.$route.params.routeToGoBack;
  },

  watch: {
    loadView(val) {
      if (!val) {
        this.$store.dispatch("user/changeAppLoading", {
          status: false,
          text: "",
          opaque: false
        });
      }
    },
    // comprobantes: {
    //   handler(newVal) {
    //     // Recalcular los totales cuando los comprobantes cambian
    //     if (!this.loadView) {
    //       if (this.showCotizacion) {
    //         this.totalNc = this.editSoloImputacionNC
    //           ? this.totalNc
    //           : newVal.reduce((acc, item) => {
    //               return acc + parseFloat(item.cancelaComp ?? 0);
    //             }, 0);
    //       } else {
    //         this.totalNc = this.editSoloImputacionNC
    //           ? this.totalNc
    //           : newVal.reduce((acc, item) => {
    //               return acc + parseFloat(item.cancela ?? 0);
    //             }, 0);
    //       }
    //       // if (!this.empresaMultijurisdiccion) {
    //       //   this.updateTotales(this.comprobantes);
    //       // }
    //     }
    //   },
    //   deep: true
    // },
    fechaContable(val) {
      if (val != null) {
        this.setImputacionContableEmpresa();
      }
    }
  },
  computed: {
    totalesCancela() {
      return this.comprobantes.reduce((acc, item) => {
        return (
          acc +
          (this.showCotizacion
            ? parseFloat(item.cancelaComp)
            : parseFloat(item.cancela))
        );
      }, 0);
    },
    valorNcValido() {
      let notaCreditoEnCero =
        this.permitirCargarNCCeroProv && this.totalNc == 0;
      let notaCreditoConValor = this.totalNc > 0;
      let sumaCamposValida =
        this.netoNc +
        this.ivaNc +
        this.percepIvaNc +
        (this.empresaMultijurisdiccion ? this.totalPercIIBB : this.percepIbNc) +
        this.impInternosNc +
        this.otrosConceptosNc;

      return (
        parseFloat(sumaCamposValida.toFixed(2)) == this.totalNc &&
        (notaCreditoEnCero || notaCreditoConValor)
      );
    },
    showCotizacion() {
      let monedaNcDistinta = true;
      if (this.monedaNcSelected?.id == undefined) {
        monedaNcDistinta =
          this.monedaNcSelected != null &&
          this.monedaNcSelected != this.monedaPorDefecto;
      } else {
        monedaNcDistinta =
          this.monedaNcSelected != null &&
          this.monedaNcSelected.id != this.monedaPorDefecto;
      }
      let monedaProvDistinta =
        this.monedaProvSelected != null &&
        this.monedaProvSelected.id != this.monedaPorDefecto;

      return (
        this.monedaPorDefecto != null &&
        (monedaNcDistinta || monedaProvDistinta) &&
        this.parametroGeneralMultimoneda
      );
    },

    filteredHeadersComp() {
      let monedaProvDistinta =
        this.monedaProvSelected != null &&
        this.monedaProvSelected.id != this.monedaPorDefecto;
      let filteredHeaders = [];
      if (monedaProvDistinta) {
        filteredHeaders = this.headersComprobantes.filter(
          header => header.value != "cancela" && header.value != "saldo"
          // && header.value != "cancelado"
        );
      } else {
        filteredHeaders = this.headersComprobantes.filter(
          header =>
            header.value != "cotizacionComprobante" &&
            header.value != "diferenciaCambio" &&
            // header.value != "canceladoComp" &&
            header.value != "cancelaComp" &&
            header.value != "saldoComp"
        );
      }

      return filteredHeaders;
    },

    totalPercIIBB() {
      return this.itemsPercIIBB.length > 0
        ? this.itemsPercIIBB.reduce(
            (acc, item) => acc + parseFloat(item.importe),
            0
          )
        : 0;
    },
    siglaMonedaSelected() {
      if (
        this.parametroGeneralMultimoneda &&
        this.monedaNcSelected != null &&
        this.monedaNcSelected.id != this.monedaPorDefecto
      ) {
        return this.monedaNcSelected.value2;
      } else {
        return "";
      }
    },
    netoDetallesAlicuotasIva: {
      get: function() {
        if (this.detalleAlicuotasIvaItems.length > 0) {
          return this.detalleAlicuotasIvaItems.reduce((total, detalle) => {
            if (detalle.neto !== null) {
              return total + detalle.neto;
            } else {
              return total;
            }
          }, 0);
        } else {
          return 0;
        }
      },
      set: function() {
        return null;
      }
    },
    netoDetalleAlicuotasIvaString() {
      return this.showCotizacion
        ? `${helpers.floatToMoneyString(
            this.netoDetallesAlicuotasIva,
            this.siglaMonedaSelected
          )} (${helpers.floatToMoneyString(
            this.netoDetallesAlicuotasIva * this.cotizacion,
            this.siglaMonedaPorDefecto
          )})`
        : `${helpers.floatToMoneyString(this.netoDetallesAlicuotasIva, "")}`;
    },
    ivaDetalleAlicuotasIvaString() {
      return this.showCotizacion
        ? `${helpers.floatToMoneyString(
            this.ivaDetallesAlicuotasIva,
            this.siglaMonedaSelected
          )} (${helpers.floatToMoneyString(
            this.ivaDetallesAlicuotasIva * this.cotizacion,
            this.siglaMonedaPorDefecto
          )})`
        : `${helpers.floatToMoneyString(this.ivaDetallesAlicuotasIva, "")}`;
    },
    totalDetalleAlicuotasIvaString() {
      return this.showCotizacion
        ? `${helpers.floatToMoneyString(
            this.totalDetallesAlicuotasIva,
            this.siglaMonedaSelected
          )} (${helpers.floatToMoneyString(
            this.totalDetallesAlicuotasIva * this.cotizacion,
            this.siglaMonedaPorDefecto
          )})`
        : `${helpers.floatToMoneyString(this.totalDetallesAlicuotasIva, "")}`;
    },
    ivaDetallesAlicuotasIva: {
      get: function() {
        if (this.detalleAlicuotasIvaItems.length > 0) {
          return this.detalleAlicuotasIvaItems.reduce((total, detalle) => {
            if (detalle.neto !== null) {
              return total + detalle.iva;
            } else {
              return total;
            }
          }, 0);
        } else {
          return 0;
        }
      },
      set: function() {
        return null;
      }
    },

    totalImputable() {
      let suma = this.netoNc;
      if (this.imputCbleIVA) {
        suma += this.ivaNc;
      }
      if (this.imputCbleImpInt) {
        suma += this.impInternosNc;
      }
      if (this.imputCbleOtros) {
        suma += this.otrosConceptosNc;
      }
      if (
        this.parametroGeneralMultimoneda &&
        this.monedaNcSelected != null &&
        this.monedaPorDefecto != this.monedaNcSelected.id
      ) {
        return parseFloat((suma * this.cotizacion).toFixed(2));
      } else {
        return parseFloat(suma.toFixed(2));
      }
    },
    totalDetallesAlicuotasIva() {
      if (this.detalleAlicuotasIvaItems.length > 0) {
        let suma = 0;
        suma = this.detalleAlicuotasIvaItems.reduce((total, detalle) => {
          if (detalle.total !== null) {
            return total + detalle.total;
          } else {
            return total;
          }
        }, 0);
        // suma +=
        //   (this.empresaMultijurisdiccion
        //     ? this.totalPercIIBB
        //     : this.percepcionIIBB) +
        //   this.percepcionIVA +
        //   this.impInternos +
        //   this.otrosConceptos;

        return suma;
      } else {
        return 0;
      }
    }
  },
  methods: {
    ...mapActions({
      grabarNotaCredito: "proveedores/grabarOpNc",
      grabarRelacionNotaCreditoOp: "proveedores/grabarRelacionNotaCreditoOp",

      canEditTotalNc: "proveedores/canEditTotalComprobante",

      getCondicionesIIBB: "afiliaciones/getCondicionesIIBB",
      getConceptosIIBB: "afiliaciones/getConceptosIIBB",
      getCondicionesGanancias: "taxonomy/getCondicionesGanancias",
      getConceptosGanancias: "taxonomy/getConceptosGanancias",
      getConceptosCondicionesProveedor:
        "proveedores/getConceptosCondicionesProveedor",
      fetchProveedoresByQuery: "proveedores/fetchProveedoresByQuery",
      getTipoComprobantesNotaCreditoProveedores:
        "proveedores/getTipoComprobantesNotaCreditoProveedores",
      getPlazoPagoProveedorById: "proveedores/getPlazoPagoProveedorById",
      getImputContablesByEmpresaxFechaContable:
        "proveedores/getImputContablesByEmpresaxFechaContable",
      getParametrosGeneralesProveedor:
        "proveedores/getParametrosGeneralesProveedorCargaFactura",
      getLetraTipoCompProveedor: "proveedores/getLetraTipoCompProveedor",
      getMonedaDeProveedor: "proveedores/getMonedaDeProveedor",
      getParametroGralCargaFacturaEnCero:
        "taxonomy/getParametroGralCargaFacturaEnCero",
      getEntidadesFacturantes: "proveedores/getEntidadesFacturantes",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getCotizacionMoneda: "AdministracionSistema/getCotizacionMoneda",
      getTipoComprobanteProveedorById:
        "proveedores/getTipoComprobanteProveedorById",
      getProveedoresByCod: "afiliaciones/getProveedoresByCod",
      getTipoCompTieneNumAutomatico:
        "proveedores/getTipoCompTieneNumAutomatico",
      getImputacionesContablesACancelar:
        "proveedores/getImputacionesContablesACancelar",
      getJurisdiccionesIIBBProveedor:
        "proveedores/getJurisdiccionesIIBBProveedor",
      getDetalleNotaCredito: "proveedores/getDetalleNotaCredito",
      setAlert: "user/setAlert"
    }),
    async setImputacionesContablesACancelar(esDistribucion) {
      let comprobantesCancela = this.comprobantes.map(x => ({
        movProvId: x.mProvId,
        total: this.showCotizacion
          ? x.total * x.cotizacionComprobante ?? 1
          : x.total,
        cancela: esDistribucion
          ? x.cancela
          : this.showCotizacion
          ? x.total * this.cotizacion ?? 1
          : x.total,
        cancelaComp: esDistribucion
          ? x.cancelaComp
          : this.showCotizacion
          ? x.total * this.cotizacion ?? 1
          : x.total
      }));
      const data = {
        comprobantes: comprobantesCancela,
        totalImputable: this.totalImputable
      };
      const response = await this.getImputacionesContablesACancelar(data);
      if (response.centrosCostosList != null) {
        this.totalCentrosDeCostos = response.centrosCostosList.reduce(
          (sum, cc) => {
            return sum + cc.importe;
          },
          0
        );
        this.centroCostosItems = response.centrosCostosList.map(
          (centro, index) => {
            return {
              ...centro,
              internId: index + 1
            };
          }
        );
      }
      if (response.imputContablesList != null) {
        this.totalImputacionContable = response.imputContablesList.reduce(
          (sum, impCont) => {
            return sum + impCont.importe;
          },
          0
        );
        this.imputacionContableItems = response.imputContablesList.map(
          (imputCble, index) => {
            return {
              ...imputCble,
              internId: index + 1
            };
          }
        );
        this.totalGastoAImputar = this.imputacionContableItems.reduce(
          (total, item) => {
            return total + (item.esEgreso ? item.importe : 0);
          },
          0
        );
      }
      return response;
    },
    calculoDiferenciaDeCambio(item) {
      let result = `${this.helpers.floatToMoneyString(
        item.cancelaComp,
        "",
        true
      )} * ${this.helpers.floatToMoneyString(
        this.cotizacion,
        "",
        true
      )} - ${this.helpers.floatToMoneyString(
        item.cancelaComp,
        "",
        true
      )} * ${this.helpers.floatToMoneyString(
        item.cotizacionComprobante,
        "",
        true
      )} = ${this.helpers.floatToMoneyString(item.diferenciaCambio, "", true)}`;
      return result;
    },
    goToVerComprobante(item) {
      this.paramsToSeeComp = {
        movProvId: item.mProvId,
        monedaToDetalle: this.monedaProvSelected,
        fechaCotizaToDetalle: this.fecha
      };
      this.openSeeComp = true;
    },
    async toggleConfirmDialogNotaCreditoEnCero(confirm) {
      this.confirmDialogNcEnCero = !this.confirmDialogNcEnCero;
      this.flagConfirmNcEnCero = confirm;
      if (this.flagConfirmNcEnCero) {
        await this.saveNotaCredito();
      }
    },
    async saveNotaCredito() {
      if (this.fechaContable < this.fecha) {
        this.alertValidacionFechaContable();
        return;
      }
      if (
        this.totalNc == 0 &&
        this.permitirCargarNCCeroProv &&
        !this.flagConfirmNcEnCero
      ) {
        this.toggleConfirmDialogNotaCreditoEnCero(false);
        return;
      }
      if (this.comprobantes.length > 0 && this.totalNc < this.totalesCancela) {
        this.setAlert({
          type: "warning",
          message:
            "El total de la nota de crédito es menor a la sumatoria de los comprobantes a cancelar."
        });
        return;
      }
      // if (this.totalPercIIBB != this.percepIbNc)
      if (
        this.netoNc != this.netoDetallesAlicuotasIva ||
        this.ivaNc != this.ivaDetallesAlicuotasIva
      ) {
        this.setAlert({
          type: "warning",
          message:
            "No coinciden los importes de neto o IVA entre la nota de crédito y los detalles de alícuotas."
        });
        return;
      }
      if (
        this.tipoCompLlevaImpContable &&
        this.totalImputable != this.totalImputacionContable
      ) {
        this.setAlert({
          type: "warning",
          message:
            "El total imputable no coincide con el total de imputación contable."
        });
        return;
      }
      if (
        this.tipoCompLlevaImpContable &&
        this.totalCentrosDeCostos != this.totalGastoAImputar
      ) {
        this.setAlert({
          type: "warning",
          message:
            "Los gastos a imputar no coinciden con el total de centros de costos."
        });
        return;
      }

      this.loadingSaveBtn = true;
      const data = {
        MProv_id: this.movProvId ?? 0,
        tipoComprobanteDif: "Nc",
        Fecha: this.fecha,
        // FechaVenc: null,
        FecContable: this.fechaContable,
        ProvId: this.provId,
        nombre: this.proveedorSelected.value1,
        cuit: this.proveedorSelected.cuit,
        direccion: this.proveedorSelected.domicilio,
        TCompId: this.tipoComprobanteSelected,
        tcompTieneNumeradorAutomatico: this.tieneNumeradorAutomatico,
        signo: this.tCompSigno,
        Letra: this.letraComprobante,
        Suc: this.sucursalComprobante,
        Numero: this.numeroComprobante ?? 0,
        TIvaId: this.tipoIvaProv,
        Neto: this.showCotizacion
          ? parseFloat((this.netoNc * this.cotizacion).toFixed(2))
          : this.netoNc,
        IVA: this.showCotizacion
          ? parseFloat((this.ivaNc * this.cotizacion).toFixed(2))
          : this.ivaNc,
        PercepcionIva: this.showCotizacion
          ? parseFloat((this.percepIvaNc * this.cotizacion).toFixed(2))
          : this.percepIvaNc,
        PercepcionIB: this.showCotizacion
          ? parseFloat(
              (
                (this.empresaMultijurisdiccion
                  ? this.totalPercIIBB
                  : this.percepIbNc) * this.cotizacion
              ).toFixed(2)
            )
          : this.empresaMultijurisdiccion
          ? this.totalPercIIBB
          : this.percepIbNc,
        ImpuestosInternos: this.showCotizacion
          ? parseFloat((this.impInternosNc * this.cotizacion).toFixed(2))
          : this.impInternosNc,
        OtrosConceptos: this.showCotizacion
          ? parseFloat((this.otrosConceptosNc * this.cotizacion).toFixed(2))
          : this.otrosConceptosNc,
        Total: this.showCotizacion
          ? parseFloat((this.totalNc * this.cotizacion).toFixed(2))
          : this.totalNc,
        MonedaId: this.monedaPorDefecto,
        EntFacId: this.entFacId,
        Obs: this.conceptoPago,
        //ver que al cambiar de proveedor a pesos la monedaNC vuelva a null ? o mandar la moneda
        MonedaIdComp: this.showCotizacion ? this.monedaNcSelected.id : null,
        NetoComp: this.showCotizacion ? this.netoNc : null,
        IVAComp: this.showCotizacion ? this.ivaNc : null,
        PercepcionIvaComp: this.showCotizacion ? this.percepIvaNc : null,
        PercepcionIBComp: this.showCotizacion
          ? this.empresaMultijurisdiccion
            ? this.totalPercIIBB
            : this.percepIbNc
          : null,
        ImpuestosInternosComp: this.showCotizacion ? this.impInternosNc : null,
        OtrosConceptosComp: this.showCotizacion ? this.otrosConceptosNc : null,
        TotalComp: this.showCotizacion ? this.totalNc : null,
        Cotizacion: this.cotizacion,
        ListaPercepcionIB: this.itemsPercIIBB,
        ListaAlicuota: this.detalleAlicuotasIvaItems,
        ListaImputContable: this.imputacionContableItems,
        ListaCentrosCostos: this.centroCostosItems,
        ListaComprobante: this.comprobantes,
        ListaValores: [],
        editaImputacion: this.editSoloImputacionNC
      };
      try {
        const response = this.editSoloImputacionNC
          ? await this.grabarRelacionNotaCreditoOp(data)
          : await this.grabarNotaCredito(data);
        if (response && response.idError == 0) {
          this.setAlert({
            type: "success",
            message: `Nota de crédito grabada con éxito ${response.mensaje}`
          });
          this.volver(false);
        } else {
          this.setAlert({
            type: "error",
            message: `Error al grabar nota de crédito: ${response.mensaje}`
          });
        }
      } catch {
      } finally {
        this.loadingSaveBtn = false;
      }
    },
    async setDatosNotaCreditoEdit() {
      const response = await this.getDetalleNotaCredito(this.movProvId);
      this.title = "Edición de comprobante";

      this.tipoComprobanteSelected = response.tipoCompId;
      this.tipoComprobanteNom = response.tipoCompNombre;
      this.tieneNumeradorAutomatico = response.tipoCompNumeradorAuto;
      this.tipoCompLlevaImpContable = response.tipoCompLlevaImputCble;
      this.tCompSigno = response.tipoCompSigno;
      this.tipoCompEditaFecha = response.tCompEditaFecha;

      // fecha
      this.fechaSelected = response.fecha;
      let partesFechaToFomat = this.fechaSelected.split("/");
      this.fecha = `${partesFechaToFomat[2]}-${partesFechaToFomat[1]}-${partesFechaToFomat[0]}`;

      // fecha contable
      this.fechaContableSelected = response.fechaCont;
      let partesFechaContableToFomat = this.fechaContableSelected.split("/");
      this.fechaContable = `${partesFechaContableToFomat[2]}-${partesFechaContableToFomat[1]}-${partesFechaContableToFomat[0]}`;
      // fecha venc. CAE

      this.letraComprobante = response.letra;
      this.sucursalComprobante = response.sucursal;
      this.numeroComprobante = response.numero;
      this.monedaSelected = this.monedas.filter(
        x => x.id == response.monedaId
      )[0];
      this.cotizacion = response.cotizacion;

      this.conceptoPago = response.observaciones;
      this.netoNc = response.neto;
      this.ivaNc = response.iva;
      this.percepIvaNc = response.percIVA;
      this.percepIbNc = response.percIB;
      this.impInternosNc = response.impInternos;
      this.otrosConceptosNc = response.otrosConceptos;
      this.totalNc = response.total;

      this.tieneAsientoContable = response.haveAsientoCtble;
      this.fecContMenorFecCierre = response.fecContMenorFecCierre;
      this.numeroExpediente = response.numeroExpediente;
      if (this.numeroExpediente > 0) {
        this.toggleConfirmDialog();
      }

      if (response.centrosCostosList != null) {
        this.totalCentrosDeCostos = parseFloat(
          response.centrosCostosList
            .reduce((sum, cc) => {
              return sum + cc.importe;
            }, 0)
            .toFixed(2)
        );
        this.centroCostosItems = response.centrosCostosList.map(
          (centro, index) => {
            return {
              ...centro,
              internId: index + 1
            };
          }
        );
      }
      if (response.imputContablesList != null) {
        this.totalImputacionContable = response.imputContablesList.reduce(
          (sum, impCont) => {
            return sum + impCont.importe;
          },
          0
        );
        this.imputacionContableItems = response.imputContablesList.map(
          (imputCble, index) => {
            return {
              ...imputCble,
              internId: index + 1
            };
          }
        );
        this.totalGastoAImputar = this.imputacionContableItems.reduce(
          (total, item) => {
            return total + (item.importe && item.esEgreso ? item.importe : 0);
          },
          0
        );
      }

      if (
        response.detalleAlicuotasList != null &&
        response.detalleAlicuotasList.length > 0
      ) {
        this.detalleAlicuotasIvaItems = response.detalleAlicuotasList.map(
          (detalle, index) => {
            return {
              ...detalle,
              internId: index + 1
            };
          }
        );
      }
      if (response.detallePercepcionIB.length > 0) {
        this.itemsPercIIBB = response.detallePercepcionIB.map(
          (percIB, index) => {
            return {
              ...percIB,
              id: index + 1
            };
          }
        );
      }
      await this.setEmpresaProveedor(response.entFacId, response.provCodigo);

      if (response.listaComprobante != null) {
        this.comprobantes = response.listaComprobante.map(comprobante => ({
          ...comprobante
        }));
      }
      // this.editSoloImputacionNC = this.editSoloImputacionNC ||  this.comprobantes.some(x => x.tieneDifCambioConAsientoCont == true)
    },
    async setParametrosGeneralesProveedores() {
      const parametrosGen = await this.getParametrosGeneralesProveedor();

      this.permitirCargarNCCeroProv = parametrosGen.permitirCargarFacCeroProv;
      this.parametroGeneralMultimoneda = parametrosGen.multimoneda;
      this.parametroGeneralBloqueaLetraComp =
        parametrosGen.bloquearLetraCompProv;
      this.validarFecCbleFacProv = parametrosGen.validarFecCbleFacProv;
      this.imputCbleIVA = parametrosGen.imputCbleIVA;
      this.imputCbleImpInt = parametrosGen.imputCbleIMPINT;
      this.imputCbleOtros = parametrosGen.imputCbleOTROS;
    },
    async setImputacionContableEmpresa() {
      if (
        this.entFacId != null &&
        this.fechaContable != null &&
        this.tipoCompLlevaImpContable
      ) {
        try {
          const data = {
            entFacId: this.entFacId,
            fechaContable: this.fechaContable
          };
          const response = await this.getImputContablesByEmpresaxFechaContable(
            data
          );

          this.datosImputacionContable = response;
          this.empresaIdConta = response.empresaIdConta;
          this.fechaFinEjercicio = response.fechaFinEjercicio;
          this.fechaInicioEjercicio = response.fechaInicioEjercicio;
        } catch (error) {
          this.datosImputacionContable = null;
          this.empresaIdConta = null;
        }
        return true;
      } else {
        return false;
      }
    },
    setDatosEmpresa() {
      if (this.empresaSelected != null) {
        this.entFacId = this.empresaSelected.id;
        this.empresaMultijurisdiccion = this.empresaSelected.esMultiJurisdiccion;
      } else {
        this.entFacId = null;
        this.empresaMultijurisdiccion = false;
      }
    },
    async setEmpresaProveedor(entFacId, provCod) {
      if (entFacId != null) {
        this.empresaSelected = this.empresas.find(x => x.id == entFacId);
        this.setDatosEmpresa();
        this.setImputacionContableEmpresa();
        this.getTiposComprobantes();
        if (this.movProvId == null) this.getLetraComprobanteByProvEntFacId();
      }
      if (provCod != null) {
        this.provCod = provCod;
        await this.buscarProveedor();
      }
    },
    async getLetraComprobanteByProvEntFacId() {
      if (
        this.entFacId != null &&
        this.provId != null &&
        this.movProvId == null
      ) {
        const letraResponse = await this.getLetraTipoCompProveedor({
          entFacId: this.entFacId,
          provId: this.provId
        });
        if (letraResponse != null && letraResponse.length > 0) {
          this.letraComprobante = letraResponse;
          this.letraComprobanteIvaRela = letraResponse;
        } else {
          this.letraComprobanteIvaRela = null;
        }
      }
    },
    distribuirComprobantes() {
      this.bloqueaDistribuirComprobantes = true;
      let saldoDisponible = parseFloat(this.totalNc.toFixed(2)); // Aseguramos decimales

      if (this.showCotizacion) {
        this.comprobantes.forEach(comprobante => {
          let saldoComprobante = parseFloat(comprobante.saldoComp.toFixed(2)); // Decimales

          if (saldoDisponible >= saldoComprobante) {
            comprobante.cancelaComp = saldoComprobante;
            saldoDisponible -= saldoComprobante;
          } else {
            comprobante.cancelaComp = saldoDisponible;
            saldoDisponible = 0;
          }
        });
        if (!this.editSoloImputacionNC) {
          this.comprobantes = this.comprobantes.filter(x => x.cancelaComp > 0);
        }
      } else {
        this.comprobantes.forEach(comprobante => {
          let saldoComprobante = parseFloat(comprobante.saldo.toFixed(2)); // Decimales

          if (saldoDisponible >= saldoComprobante) {
            comprobante.cancela = saldoComprobante;
            saldoDisponible -= saldoComprobante;
          } else {
            comprobante.cancela = saldoDisponible;
            saldoDisponible = 0;
          }
        });
        if (!this.editSoloImputacionNC) {
          this.comprobantes = this.comprobantes.filter(x => x.cancela > 0);
        }
      }

      this.calcularDiferenciaCambio(this.cotizacion); // Llama a la función de diferencia de cambio
    },

    calcularDiferenciaCambio(cotizacionParam) {
      if (
        !cotizacionParam ||
        !Array.isArray(this.comprobantes) ||
        !this.showCotizacion
      ) {
        // Si no se ha ingresado una cotización válida o el array de comprobantes no existe, no hacemos nada
        return;
      }

      this.comprobantes = this.comprobantes.map(comprobante => {
        // Verifica si los valores existen y son válidos, en caso de no existir, se usan valores por defecto
        let cancelaComp = comprobante.cancelaComp || 0;
        let cotizacionComprobante = comprobante.cotizacionComprobante || 1; // Evitar división por 0

        // Calcula la diferencia de cambio
        comprobante.diferenciaCambio =
          cancelaComp * (cotizacionParam - cotizacionComprobante);

        // Calcula el monto a cancelar en pesos argentinos según la nueva cotización
        comprobante.cancela =
          cancelaComp *
          (comprobante.diferenciaCambio > 0
            ? comprobante.cotizacionComprobante
            : this.cotizacion ?? 1);

        // Retorna un nuevo objeto con el valor actualizado
        return { ...comprobante };
      });
    },

    setFechas() {
      let today = new Date();
      let año = today.getFullYear();
      let mes = (today.getMonth() + 1).toString().padStart(2, "0"); // Agregar 1 porque getUTCMonth() devuelve el mes en base 0

      let dia = today
        .getDate()
        .toString()
        .padStart(2, "0");
      let date = `${año}-${mes}-${dia}`;
      let dateFormated = this.helpers.formatDate(date);

      this.fecha = date;
      this.fechaContable = date;
      this.fechaSelected = dateFormated;
      this.fechaContableSelected = dateFormated;
    },

    async buscarProveedor() {
      if (
        this.provCod !== null &&
        this.provCod !== undefined &&
        this.provCod !== ""
      ) {
        try {
          this.proveedoresLoading = true;
          // if(numberProv == null)

          this.proveedores = await this.getProveedoresByCod(this.provCod);
          if (this.proveedores.length === 0) {
            this.setAlert({
              type: "warning",
              message: "Proveedor inexistente"
            });
            this.provId = null;
            this.proveedorSelected = null;
            this.provNom = null;
            this.proveedoresLoading = false;
            return;
          }
          this.proveedoresLoading = false;
          this.proveedorSelected = this.proveedores[0];
          this.provId = this.proveedorSelected.id;
          this.provNom = this.proveedorSelected.value1.toUpperCase();
          this.cuit = this.proveedorSelected.cuit;
          if (this.movProvId == null) {
            this.comprobantes = [];
          }
          this.setCondicionesConceptosProveedor();
          this.getMonedaProveedor();
          this.getLetraComprobanteByProvEntFacId();
          //   this.setPlazoDePagoProveedor();
        } catch (error) {
          this.proveedoresLoading = false;
          this.proveedorSelected = null;
          this.provId = null;
          this.bloqueaMoneda = false;
        }
      } else {
        this.proveedorSelected = null;
        this.provId = null;
        this.provNom = null;
        this.bloqueaMoneda = false;
        this.monedaProvSelected = null;
        this.comprobantes = [];
      }
    },
    bloqueaFechasxTipoComp() {
      let today = new Date();
      let año = today.getFullYear();
      let mes = today.getMonth() + 1;
      let dia = today.getDate();

      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes; //para que el mes tenga dos digitos y lo reconozca el datepicker
      let date = `${año}-${mes}-${dia}`;
      let dateFormated = this.helpers.formatDate(date);
      this.fecha = date;
      this.fechaContable = date;
      this.fechaSelected = dateFormated;
      this.fechaContableSelected = dateFormated;
    },
    alertValidacionFechaContable() {
      this.setAlert({
        type: "warning",
        message:
          "La fecha contable no puede ser anterior a la fecha de comprobante"
      });
    },
    findProveedor(proveedor) {
      this.proveedorSelected = proveedor;
      this.provId = this.proveedorSelected.provId;
      this.provNom = proveedor.provNom;
      this.provCod = proveedor.provCod;
      this.proveedorSelected.value1 = this.provNom.toUpperCase();
      this.proveedorSelected.value2 = this.provCod;
      this.proveedorSelected.id = this.provId;
      this.comprobantes = [];
      this.setCondicionesConceptosProveedor();
      this.getMonedaProveedor();
      //   this.setPlazoDePagoProveedor();
      this.getLetraComprobanteByProvEntFacId();
      //   this.cleanArrays();
    },
    async getMonedaProveedor() {
      if (this.provId != null) {
        this.monedaProveedorLoading = true;
        const response = await this.getMonedaDeProveedor(this.provId);
        if (response != null) {
          this.monedaProvSelected = this.monedas.find(x => x.id == response.id);
          this.monedaNcSelected = this.monedaProvSelected;
          this.bloqueaMoneda = response.haveCompAsignados;
          if (this.movProvId == null && this.parametroGeneralMultimoneda)
            this.getCotizacion(this.monedaProvSelected?.id);
        }
      } else this.bloqueaMoneda = false;
      this.monedaProveedorLoading = false;
    },

    async setDatosTipoComprobante() {
      if (this.tipoComprobanteSelected != null) {
        this.tieneNumeradorAutomatico = false;
        const datosTipoComprobante = await this.getTipoComprobanteProveedorById(
          this.tipoComprobanteSelected
        );
        this.tipoCompLlevaImpContable = datosTipoComprobante.llevaImputCble;
        this.tipoCompEditaFecha = datosTipoComprobante.tCompEditaFecha;
        this.tCompSigno = datosTipoComprobante.tCompSigno;
        if (this.tipoCompEditaFecha == false) {
          this.bloqueaFechasxTipoComp();
        }
        await this.setImputacionContableEmpresa();

        if (datosTipoComprobante.numAutoLetra.trim().length > 0) {
          this.letraComprobante = datosTipoComprobante.numAutoLetra;
        }
        else if (this.letraComprobanteIvaRela != null) {
          this.letraComprobante = this.letraComprobanteIvaRela;
        }
        if (datosTipoComprobante.numAutoSuc > 0) {
          this.sucursalComprobante = datosTipoComprobante.numAutoSuc;
        }
        await this.getTipoCompNumAutomatico(
          this.tipoComprobanteSelected,
          datosTipoComprobante.numAutoLetra,
          datosTipoComprobante.numAutoSuc
        );
        if (
          datosTipoComprobante.numAuto == true &&
          this.tieneNumeradorAutomatico == false
        ) {
          this.setAlert({
            type: "warning",
            message: `No está configurado el numerador autómatico. \n Tipo de comprobante: '${datosTipoComprobante.tCompNombre}' \nLetra: '${datosTipoComprobante.numAutoLetra}' \nSucursal: '${datosTipoComprobante.numAutoSuc}'`
          });
        } else if (
          datosTipoComprobante.numAuto == true &&
          this.tieneNumeradorAutomatico == true
        ) {
          this.numeroComprobante = 0;
        }
      }
    },
    async getTipoCompNumAutomatico(tCompId, letra, sucursal) {
      if (this.movProvId == null && letra && tCompId && sucursal) {
        if (tCompId != null && letra != null && sucursal != null) {
          this.tieneNumeradorAutomatico = await this.getTipoCompTieneNumAutomatico(
            {
              tCompId: tCompId,
              numAutoSuc: sucursal,
              numAutoLetra: letra
            }
          );
          this.numeroComprobante = this.tieneNumeradorAutomatico
            ? 0
            : this.numeroComprobante;
        } else this.tieneNumeradorAutomatico = false;
      }
    },
    async setCondicionesConceptosProveedor() {
      if (this.provId != null) {
        const response = await this.getConceptosCondicionesProveedor(
          this.provId
        );

        this.tipoIvaProv = response.iva?.id;
        this.tipoIvaProvNom = response.iva?.value;
      }
    },
    async getTiposComprobantes() {
      if (this.empresaSelected != null) {
        this.tiposComprobantesLoading = true;
        this.tiposComprobantes = await this.getTipoComprobantesNotaCreditoProveedores(
          this.empresaSelected.id
        );
        this.tiposComprobantesLoading = false;
      }
    },
    toggleModalBusquedaProv() {
      this.buscarProveedorModal = !this.buscarProveedorModal;
    },
    toggleNuevoDetalleAlicuotasIva() {
      this.itemsCount = this.detalleAlicuotasIvaItems.length;
      this.detalleAlicuotasIvaModal = !this.detalleAlicuotasIvaModal;
      if (!this.detalleAlicuotasIvaModal) {
        this.$nextTick(() => {
          this.detalleAlicuotaToEdit = null;
          this.focusBtnNuevoDetalleAlicuotasIva();
        });
      }
    },
    focusBtnNuevoDetalleAlicuotasIva() {
      this.$refs["btnToggleNuevoDetalleAlicuotasIva"].$el.focus();
    },
    editDetalleAlicuotaIva(detalle) {
      let item = this.detalleAlicuotasIvaItems.find(
        x => x.internId == detalle.internId
      );
      let itemIndex = this.detalleAlicuotasIvaItems.indexOf(item);
      let editedItem = {
        ...this.detalleAlicuotasIvaItems[itemIndex],
        ...{
          alicuotaIVA: detalle.alicuotaIVA,
          concepto: detalle.concepto,
          iva: detalle.iva,
          neto: detalle.neto,
          ivaPorcentaje: detalle.ivaPorcentaje,
          ivaPorcId: detalle.ivaPorcId,
          total: detalle.total
        }
      };
      this.detalleAlicuotasIvaItems.splice(itemIndex, 1, editedItem);
    },
    addDetalleAlicuotaIva(detalle) {
      this.detalleAlicuotasIvaItems.push(detalle);
    },
    editDetalleAlicuota(item) {
      this.detalleAlicuotaToEdit = item;
      this.itemsCount = this.detalleAlicuotasIvaItems.length;
      this.toggleNuevoDetalleAlicuotasIva();
    },
    deleteDetalleAlicuota(item) {
      this.detalleAlicuotasIvaItems = this.detalleAlicuotasIvaItems.filter(
        x => x.internId !== item.internId
      );
    },
    async toggleModalPercIIBB() {
      this.modalPercIIBB = !this.modalPercIIBB;
      if (this.modalPercIIBB) {
        const data = {
          entFacId: this.entFacId,
          provId: this.provId,
          fecha: this.fecha
        };
        this.jurisdicciones = await this.getJurisdiccionesIIBBProveedor(data);
      } else {
        this.selectedJurisdiccion = null;

        this.importeItemPercIIBB = null;
        this.internIdPercIIBB = null;
        this.$nextTick(() => {
          document.querySelector(`#impInternosNc`).focus();
        });
      }
    },
    addJurisdiccion() {
      const exists = this.itemsPercIIBB.some(
        item =>
          item.jurIbId === this.selectedJurisdiccion.jurIbId &&
          item.id != this.internIdPercIIBB
      );

      if (exists) {
        this.setAlert({
          type: "warning",
          message: "La jurisdicción seleccionada ya existe en la lista."
        });
        return;
      }
      if (this.internIdPercIIBB != null) {
        // Encuentra el índice del elemento a actualizar
        const index = this.itemsPercIIBB.findIndex(
          item => item.id === this.internIdPercIIBB
        );

        if (index !== -1) {
          // Actualiza el elemento en el array usando $set para asegurar la reactividad
          this.$set(this.itemsPercIIBB, index, {
            id: this.internIdPercIIBB,
            jurIbId: this.selectedJurisdiccion.jurIbId,
            jurIbNombre: this.selectedJurisdiccion.jurIbNombre,
            importe: this.importeItemPercIIBB
          });

          // Resetea la variable de internId para futuras adiciones
          this.internIdPercIIBB = null;

          this.setAlert({
            type: "success",
            message: "El registro ha sido actualizado correctamente."
          });
        } else {
          this.setAlert({
            type: "warning",
            message: "No se encontró el registro a actualizar."
          });
        }
      } else {
        const maxInternId =
          this.itemsPercIIBB.length > 0
            ? Math.max(...this.itemsPercIIBB.map(item => item.id))
            : 0;
        const newItem = {
          id: maxInternId + 1,
          jurIbNombre: this.selectedJurisdiccion.jurIbNombre,
          jurIbId: this.selectedJurisdiccion.jurIbId,
          importe: this.importeItemPercIIBB
        };
        this.$set(this.itemsPercIIBB, this.itemsPercIIBB.length, newItem);
        this.setAlert({
          type: "success",
          message: "El registro ha sido agregado correctamente."
        });
        // this.itemsPercIIBB.push(newItem);
      }
      this.updateTotalNc();
      this.importeItemPercIIBB = null;
      this.$refs["jurisdiccionesIIBBForm"].reset();
    },
    removeItem(item) {
      this.itemsPercIIBB = this.itemsPercIIBB.filter(i => i.id !== item.id);
      this.updateTotalNc();
    },
    editPercIIBB(itemToEdit) {
      this.selectedJurisdiccion = this.jurisdicciones.find(
        x => x.jurIbId == itemToEdit.jurIbId
      );

      this.importeItemPercIIBB = itemToEdit.importe;
      this.internIdPercIIBB = itemToEdit.id;
    },
    reloadTablesImputacionContable(
      centrosCostos,
      imputacionContable,
      totalCentrosDeCostos,
      totalImputacionContable,
      totalGastoAImputar
    ) {
      this.centroCostosItems = centrosCostos;
      this.imputacionContableItems = imputacionContable;
      this.totalCentrosDeCostos = totalCentrosDeCostos;
      this.totalImputacionContable = totalImputacionContable;
      this.totalGastoAImputar = totalGastoAImputar;
    },
    toggleNuevoImputacionContable() {
      this.imputacionContableModal = !this.imputacionContableModal;

      if (!this.imputacionContableModal) {
        this.centroCostosItemsProp = [];
        this.imputacionContableItemsProp = [];
      } else {
        this.centroCostosItemsProp = this.centroCostosItems;
        this.imputacionContableItemsProp = this.imputacionContableItems;
      }
    },
    toggleModalComprobantesCancelar() {
      this.buscarComprobantesCancelar = !this.buscarComprobantesCancelar;
    },
    setComprobantesCancelar(itemsComprobantesACancelar) {
      this.bloqueaDistribuirComprobantes = true;
      itemsComprobantesACancelar.forEach(x => {
        const exists = this.comprobantes.some(c => c.mProvId === x.mProvId);
        if (!exists) {
          this.comprobantes.push(x);
        }
      });
      this.comprobantes.sort(
        (a, b) => new Date(a.fechaComprobante) - new Date(b.fechaComprobante)
      );
      if (this.editSoloImputacionNC) {
        // this.distribuirComprobantes();
      } else {
        this.calcularDiferenciaCambio(this.cotizacion);
      }
      // if (!this.empresaMultijurisdiccion) {
      //   this.updateTotales(this.comprobantes);
      // }
    },
    updateTotales(comprobantes) {
      if (comprobantes.length > 0) {
        // Calculamos cada valor redondeando a dos decimales
        this.ivaNc = comprobantes.reduce((acc, item) => {
          let factor = parseFloat(
            (this.showCotizacion ? item.cancelaComp : item.cancela) / item.total
          );
          return acc + parseFloat((item.iva * factor).toFixed(2));
        }, 0);

        this.percepIvaNc = comprobantes.reduce((acc, item) => {
          let factor = parseFloat(
            (this.showCotizacion ? item.cancelaComp : item.cancela) / item.total
          );
          return acc + parseFloat((item.percepcionIva * factor).toFixed(2));
        }, 0);

        this.percepIbNc = comprobantes.reduce((acc, item) => {
          let factor = parseFloat(
            (this.showCotizacion ? item.cancelaComp : item.cancela) / item.total
          );
          return acc + parseFloat((item.percepcionIB * factor).toFixed(2));
        }, 0);
        this.impInternosNc = comprobantes.reduce((acc, item) => {
          let factor = parseFloat(
            (this.showCotizacion ? item.cancelaComp : item.cancela) / item.total
          );
          return acc + parseFloat((item.impuestosInternos * factor).toFixed(2));
        }, 0);

        this.otrosConceptosNc = comprobantes.reduce((acc, item) => {
          let factor = parseFloat(
            (this.showCotizacion ? item.cancelaComp : item.cancela) / item.total
          );
          return acc + parseFloat((item.otrosConceptos * factor).toFixed(2));
        }, 0);

        // Calcular netoNc asegurando que la suma de todas las partes sea precisa
        this.netoNc = parseFloat(
          (
            this.totalNc -
            this.ivaNc -
            this.percepIvaNc -
            this.percepIbNc -
            this.impInternosNc -
            this.otrosConceptosNc
          ).toFixed(2)
        );
      } else {
        this.totalNc = 0;
        this.netoNc = 0;
        this.ivaNc = 0;
        this.percepIvaNc = 0;
        this.percepIbNc = 0;
        this.itemsPercIIBB = [];
        this.impInternosNc = 0;
        this.otrosConceptosNc = 0;
      }

      // Volvemos a calcular totalNc para asegurarnos que la suma sea consistente
      this.totalNc = parseFloat(
        (
          this.netoNc +
          this.ivaNc +
          this.percepIvaNc +
          (this.empresaMultijurisdiccion
            ? this.totalPercIIBB
            : this.percepIbNc) +
          this.impInternosNc +
          this.otrosConceptosNc
        ).toFixed(2)
      );
    },

    updateTotalNc() {
      this.bloqueaDistribuirComprobantes = false;

      this.totalNc =
        this.netoNc +
        this.ivaNc +
        this.percepIvaNc +
        (this.empresaMultijurisdiccion ? this.totalPercIIBB : this.percepIbNc) +
        this.impInternosNc +
        this.otrosConceptosNc;

      // if (this.comprobantes.length > 0 && this.totalNc > this.totalesCancela) {
      //   this.totalNc = this.totalesCancela;
      //   this.updateTotales(this.comprobantes);
      // }
    },
    volver(vuelveSinGuardar) {
      if (vuelveSinGuardar) {
        this.$router.push({
          name: this.routeToGo,
          params: {
            empresaSelected: this.empresaSelected,
            proveedorSelected: this.proveedorSelected,
            fechaDesde: this.$route.params.fechaDesde,
            fechaHasta: this.$route.params.fechaHasta
          }
        });
      } else {
        this.$router.push({
          name: this.routeToGo,
          params: {
            empresaSelected: this.empresaSelected,
            proveedorSelected: this.proveedorSelected
          }
        });
      }
    },
    async setCombos() {
      this.empresas = await this.getEntidadesFacturantes();
      if (this.empresas.length == 1) {
        this.empresaSelected = this.empresas[0];
        this.getTiposComprobantes();
      }
      this.monedas = await this.getMonedasTaxonomy("habCarga");
      let monedaPorDefecto = this.monedas.find(x => x.bool == true);
      this.monedaNcSelected = monedaPorDefecto;
      this.monedaPorDefecto = monedaPorDefecto.id;
      this.monedaPorDefectoNom = monedaPorDefecto.value;
      this.siglaMonedaPorDefecto = this.parametroGeneralMultimoneda
        ? monedaPorDefecto.value2
        : "";
    },
    async getCotizacion(monedaId) {
      if (monedaId != null && this.movProvId == null) {
        this.cotizacionLoading = true;
        const payload = {
          monedaId: monedaId,
          fechaComprobante: this.fecha
        };
        try {
          const response = await this.getCotizacionMoneda(payload);
          this.cotizacion = response;
          this.calcularDiferenciaCambio(this.cotizacion);
        } catch (error) {
        } finally {
          this.cotizacionLoading = false;
        }
      }
    },
    quitarComprobantes() {
      this.comprobantes = this.comprobantes.filter(
        x => x.tieneDifCambioConAsientoCont
      );
    },
    exportComprobantes() {
      let result = [];
      if (this.showCotizacion) {
        this.comprobantes?.forEach(x =>
          result.push({
            ["Comprobante"]: x.nroComp,
            ["Fecha"]: x.fechaComprobanteFormatted,
            ["Vencimiento"]: x.fechaVencimientoFormatted,
            ["Total"]: x.total,
            ["Cancelado"]: x.cancelado,
            ["Saldo"]: x.saldoComp,
            ["Cancela"]: x.cancelaComp,
            [`Cancela ${this.siglaMonedaPorDefecto}`]: x.cancela,
            ["Cotización"]: x.cotizacionComprobante,
            ["Diferencia de cambio"]: x.diferenciaCambio,
            ["Formula diferencia de cambio"]: `(${x.cancelaComp} * ${this.cotizacion}) - (${x.cancelaComp} * ${x.cotizacionComprobante})`
          })
        );
      } else {
        this.comprobantes?.forEach(x =>
          result.push({
            ["Comprobante"]: x.nroComp,
            ["Fecha"]: x.fechaComprobanteFormatted,
            ["Vencimiento"]: x.fechaVencimientoFormatted,
            ["Total"]: x.total,
            ["Cancelado"]: x.cancelado,
            ["Saldo"]: x.saldo,
            ["Cancela"]: x.cancela
          })
        );
      }
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Comprobantes a cancelar");
    },
    toggleConfirmDialog() {
      this.confirmDialogExpediente = !this.confirmDialogExpediente;
      if (this.confirmDialogExpediente) {
        this.confirmDialogText = `El comprobante pertenece al expediente N°: ${this.numeroExpediente}. Los cambios no tendrán efecto en el expediente. ¿Desea modificar?`;
      }
    },
    cleanArrays() {
      if (this.movProvId == null) {
        this.tipoComprobanteSelected = null;
        this.comprobantes = [];
        this.detalleAlicuotasIvaItems = [];
        this.itemsPercIIBB = [];
        this.centroCostosItems = [];
        this.imputacionContableItems = [];
        this.totalImputacionContable = 0;
        this.totalCentrosDeCostos = 0;
        this.totalGastoAImputar = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-data-table {
  margin-bottom: 0;
}
.right-input >>> input {
  text-align: right;
}
.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 120vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
  background: transparent;

  .card-loader {
    background: transparent;
    border-radius: 20px;
    margin-top: -20vh;
    padding: 40px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}
</style>
